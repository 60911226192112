.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 800px;
  width: 100%;

  button {
    display: flex;
    justify-content: center;
    margin: 50px auto;
  }
}
.formItem {
  display: flex;
  margin: 0 10px 30px 10px;
  max-width: 800px;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  label {
    display: flex;
    flex: 1;
    max-width: 300px;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 18px;
    text-align: justify;
    margin-right: 10px;
  }
  /*   textarea {
    max-width: 300px;
  } */
}
.radio {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  align-items: center;
  margin-bottom: 40px;
  div {
    input {
      margin-right: 5px;
    }
    label {
      cursor: pointer;
      font-size: 16px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    }
  }
}
@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  display: flex;
  margin: auto;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: donut-spin 1.2s linear infinite;
}
.margins {
  display: flex;
  flex-direction: column;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  align-items: center;
}
.error {
  display: flex;
  flex: 0 0 50%;
  justify-content: center;
  margin: auto;
  text-align: center;
  color: #e53935;
}
