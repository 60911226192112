import { FastField } from "formik";

export const input = [
  { podrucniUred: "Područni ured: " },
  { ispostava: "Ispostava: " }
];
export const input1 = [
  { nazivOsobe: "1. Naziv pravne osobe: " },
  { ustrojstveniOblik: "" },
  { oib: "3. OIB Pravne osobe: " },
  { maticniBroj: "4. Matični broj upisa u sudski registar: " },
  { adresa: "5. Adresa sjedišta i obavljanja djelatnosti: " },
  { brojTel: "6. Broj telefona i telefaksa pravne osobe: " },
  { email: "7. E-mail pravne osobe: " },
  { racuni: "8. Računi - Broj glavnog žiro računa: " },
  {
    racuniNazivAdresa:
      "8. Računi - Naziv i adresa organizacije u kojoj je otvoren: "
  },
  { racuniDevizni: "8. Računi - broj deviznog računa: " }
];
export const input2 = [
  {
    osnivacImeOib: "Ime i prezime, OIB: "
  },
  {
    osnivacAdresa: "Adresa: "
  },
  { osnivacTel: "Broj telefona: " },
  {
    osnivacPutovnica: "Broj putovnice i naziv zemlje koja je izdala putovnicu: "
  }
];
export const input3 = [
  { ovlastenaIme: " Ime i prezime, OIB: " },
  { ovlastenaAdresa: " Adresa: " },
  { ovlastenaTel: " Broj telefona: " },
  {
    ovlastenaPutovnica:
      " Broj putovnice i naziv zemlje koja je izdala putovnicu: "
  }
];
export const input4 = [
  { opunomocenikIme: "Ime i prezime, OIB: " },
  { opunomocenikAdresa: "Adresa: " },
  { opunomocenikTel: "Broj telefona: " },
  {
    opunomocenikPutovnica:
      "Broj putovnice i naziv zemlje koja je izdala putovnicu: "
  }
];
export const input5 = [
  {
    vodenjeSamostalnoIme: "Ime i prezime, OIB: "
  },
  { vodenjeSamostalnoTel: "Broj telefona i telefaksa:  " },
  { vodenjeSamostalnoEmail: "E-mail adresa: " }
];
export const input6 = [
  { vodenjeKnjigovodstvoNaziv: "Ime i prezime, OIB: " },
  { vodenjeKnjigovodstvoAdresa: "Adresa" },
  {
    vodenjeKnjigovodstvoTel: "Broj telefona i telefaksa:  "
  },
  { vodenjeKnjigovodstvoEmail: "E-mail adresa: " }
];
export const input7 = [
  { osobaKnjigeIme: "Ime i prezime, OIB: " },
  {
    osobaKnjigeTel: "Broj telefona i telefaksa: "
  },
  { osobaKnjigeEmail: "E-mail adresa: " }
];
export const input8 = [
  {
    djelatnostOsnovna: "Osnovna - glavna djelatnost: "
  },
  { djelatnostOstala: "Ostala djelatnost: " }
];
export const input9 = [
  { obavljanjeDjelatnostiSjediste: "Sjedište: " },
  /*   {
    obavljanjeDjelatnostiVlastiti: "Vlastiti prostor: "
  }, */
  {
    obavljanjeDjelatnostiUkupni: "Ukupna  veličina prostora (m2): "
  }
];
export const input10 = [
  { unajmljeniProstorUkupni: "Ukupna veličina prostora(m2): " },
  {
    unajmljeniProstorNajmodavacIme: "Podaci o najmodavcu: Ime i prezime, OIB: "
  },
  { unajmljeniProstorNajmodavacAdresa: "Podaci o najmodavcu: Adresa: " }
];
export const input11 = [
  { poslovneJediniceNaziv: "Naziv: " },
  { poslovneJediniceAdresa: "Adresa" },
  { poslovneJediniceTel: "Broj telefona i telefaksa: " },
  {
    poslovneJediniceDjelatnost: "Djelatnost koja se obavlja: "
  }
];
export const input12 = [
  {
    poslovneJediniceVlastitiProstorUkupna:
      "Ukupna veličina vlastitog prostora (m2): "
  },
  {
    poslovneJediniceUnajmljeniProstor:
      "Ukupna veličina unajmljenog prostora (m2): "
  }
];
export const input13 = [
  { najmodavacIme: "Ime i prezime, OIB: " },
  { najmodavacAdresa: "Adresa: " }
];
export const input14 = [
  { brojRadnika: "Ukupan broj radnika: " },
  {
    brojRadnikaRadniOdnos: "Broj radnika u radnom odnosu: "
  },
  { brojRadnikaInozemnih: "Broj inozemnih radnika: " },
  { brojRadnikaSezonskih: "Broj sezonskih radnika: " },
  {
    brojRadnikaUcenika: "Broj učenika i studenata koji rade kod pravne osobe: "
  },
  {
    brojRadnikaOstalih: "Broj ostalih radnika (ugovor o djelu i sl.): "
  }
];
export const input15 = [
  { materijalnaImovinaVlastita: "Vlastita: " },
  { materijalnaImovinaNajam: "U najmu: " }
];
export const input16 = [
  { materijalnaImovinaVlasnik: "Ime i prezime, OIB: " },
  { materijalnaImovinaAdresa: "Adresa: " }
];
export const input17 = [
  { nematerijalnaImovinaVlastita: "Vlastita: " },
  { nematerijalnaImovinaNajam: "U najmu: " }
];
export const input18 = [
  {
    nematerijalnaImovinaVlasnik: "Ime i prezime, OIB: "
  },
  {
    nematerijalnaImovinaAdresa: "Adresa: "
  },
  { financijskaImovina: "Financijska Imovina: " }
];
export const input19 = [
  { popisImovineIme: "Ime i prezime, OIB: " },
  { popisImovineAdresa: "Adresa: " },
  { posebneNapomene: "VIII Posebne napomene: " },
  { mjestoDatum: "Mjesto i datum popunjavanja obrasca: " },
  { potpisPecat: "Potpis i pečat poreznog obveznika: " }
];
export const initValues = {
  name: "pdvPodaci",
  podrucniUred: "",
  ispostava: "",
  nazivOsobe: "",
  ustrojstveniOblik: "",
  oib: "",
  maticniBroj: "",
  adresa: "",
  brojTel: "",
  email: "",
  racuni: "",
  racuniNazivAdresa: "",
  racuniDevizni: "",
  razlog: "",
  poslovneJedinice: "",
  djelatnostJedinice: "",
  vrsteIsporuka: "",
  vodenjeKnjigovodstva: "",
  mjestoDatum: "",
  brojClanova: "",
  osnivacImeOib: "",
  osnivacAdresa: "",
  osnivacTel: "",
  osnivacPutovnica: "",
  ovlastenaIme: "",
  ovlastenaAdresa: "",
  ovlastenaTel: "",
  ovlastenaPutovnica: "",
  opunomocenikIme: "",
  opunomocenikAdresa: "",
  opunomocenikTel: "",
  opunomocenikPutovnica: "",
  vodenjeSamostalnoIme: "",
  vodenjeSamostalnoTel: "",
  vodenjeSamostalnoEmail: "",
  vodenjeKnjigovodstvoNaziv: "",
  vodenjeKnjigovodstvoAdresa: "",
  vodenjeKnjigovodstvoTel: "",
  vodenjeKnjigovodstvoEmail: "",
  osobaKnjigeIme: "",
  osobaKnjigeTel: "",
  osobaKnjigeEmail: "",
  datumUpisaURegistar: new Date(),
  datumPocetkaObavljanja: new Date(),
  djelatnostOsnovna: "",
  djelatnostOstala: "",
  obavljanjeDjelatnostiSjediste: "",
  obavljanjeDjelatnostiVlastiti: "",
  obavljanjeDjelatnostiUkupni: "",
  unajmljeniProstorUkupni: "",
  unajmljeniProstorNajmodavacIme: "",
  unajmljeniProstorNajmodavacAdresa: "",
  poslovneJediniceNaziv: "",
  poslovneJediniceAdresa: "",
  poslovneJediniceTel: "",
  poslovneJediniceDjelatnost: "",
  poslovneJediniceVlastitiProstorUkupna: "",
  poslovneJediniceUnajmljeniProstor: "",
  najmodavacIme: "",
  najmodavacAdresa: "",
  brojRadnika: "",
  brojRadnikaRadniOdnos: "",
  brojRadnikaInozemnih: "",
  brojRadnikaSezonskih: "",
  brojRadnikaUcenika: "",
  brojRadnikaOstalih: "",
  materijalnaImovinaVlastita: "",
  materijalnaImovinaNajam: "",
  materijalnaImovinaVlasnik: "",
  materijalnaImovinaAdresa: "",
  nematerijalnaImovinaVlastita: "",
  nematerijalnaImovinaNajam: "",
  nematerijalnaImovinaVlasnik: "",
  nematerijalnaImovinaAdresa: "",
  financijskaImovina: "",
  popisImovineUNajmu: "",
  popisImovineIme: "",
  popisImovineAdresa: "",
  posebneNapomene: "",
  mjestoDatum: "",
  potpisPecat: ""
};
export const sums = [
  "nightWork",
  "nightExtra",
  "nightHoliday",
  "dayWork",
  "dayExtra",
  "dayHoliday",
  /*  "fieldWork",
  "readyHours", */
  "dayRest",
  "weekRest",
  "yearRest",
  "holidays",
  "sickLeave",
  "pregnancyComplications",
  "pregnantLeave",
  "parentLeave",
  "frozenEmployment",
  "paidHolidays",
  "otherRights",
  "paidLeave",
  "unpaidLeave",
  "absenceRequest",
  "unapprovedAbsence",
  "strikeHours",
  "lockoutHours"
];
export const valuesToChoose = [
  "fieldWork",
  "readyHours",
  "dayRest",
  "weekRest",
  "yearRest",
  "holidays",
  "sickLeave",
  "pregnancyComplications",
  "pregnantLeave",
  "parentLeave",
  "frozenEmployment",
  "otherRights",
  "paidLeave",
  "unpaidLeave",
  "absenceRequest",
  "unapprovedAbsence",
  "strikeHours",
  "lockoutHours"
];
export const choosenValuesEnum = {
  fieldWork: "Terenski rad",
  readyHours: "Sati pripravnosti, te sati po pozivu",
  dayRest: "Dnevni odmor",
  weekRest: "Tjedni odmor",
  yearRest: "Godišnji odmor",
  holidays: "Plaćeni neradni dani i blagdani",
  sickLeave: "Privremena nesposobnost za rad (bolovanje)",
  pregnancyComplications: "Komplikacije u trudnoći",
  pregnantLeave: "Rodiljni dopust",
  parentLeave: "Roditeljski dopust",
  frozenEmployment: "Mirovanje radnog odnosa",
  otherRights: "Korištenje drugih prava sukladno drugim propisima",
  paidLeave: "Sati plaćenog dopusta",
  unpaidLeave: "Sati NEplaćenog dopusta",
  absenceRequest: "Izostanak na zahtjev radnika",
  unapprovedAbsence: "Izostanak s posla bez odobrenja",
  strikeHours: "Sati provedeni u štrajku",
  lockoutHours: "Sati isključenja s rada (lockout)"
};
export const months = {
  January: "Sijecanj",
  February: "Veljaca",
  March: "Ozujak",
  April: "Travanj",
  May: "Svibanj",
  June: "Lipanj",
  July: "Srpanj",
  August: "Kolovoz",
  September: "Rujan",
  October: "Listopad",
  November: "Studeni",
  December: "Prosinac"
};
export const monthsNum = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December"
};
export const monthHours = {
  "2019January": 184,
  "2019February": 160,
  "2019March": 168,
  "2019April": 176,
  "2019May": 184,
  "2019June": 160,
  "2019July": 184,
  "2019August": 176,
  "2019September": 168,
  "2019October": 184,
  "2019November": 168,
  "2019December": 176,
  "2020January": 184,
  "2020February": 160,
  "2020March": 176,
  "2020April": 176,
  "2020May": 168,
  "2020June": 176,
  "2020July": 184,
  "2020August": 168,
  "2020September": 176,
  "2020October": 176,
  "2020November": 168,
  "2020December": 184
};

export const initialDays = [
  {
    totalWorking: 0,
    "": null,
    id: "1",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "2",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "3",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "4",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "5",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "6",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "7",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "8",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "9",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "10",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "11",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "12",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "13",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "14",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "15",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "16",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "17",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "18",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "19",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "20",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "21",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "22",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "23",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "24",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "25",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "26",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "27",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "28",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "29",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "30",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  },
  {
    "": null,
    id: "31",
    workStart: null,
    workEnd: null,
    totalWork: null,
    haltStart: null,
    haltEnd: null,
    haltTotal: null,
    nightWork: null,
    nightExtra: null,
    nightHoliday: null,
    dayWork: null,
    dayExtra: null,
    dayHoliday: null,
    fieldWork: null,
    readyHours: null,
    dayRest: null,
    weekRest: null,
    yearRest: null,
    holidays: null,
    sickLeave: null,
    pregnancyComplications: null,
    pregnantLeave: null,
    parentLeave: null,
    frozenEmployment: null,
    paidHolidays: null,
    otherRights: null,
    paidLeave: null,
    unpaidLeave: null,
    absenceRequest: null,
    unapprovedAbsence: null,
    strikeHours: null,
    lockoutHours: null,
    fieldWorkFlag: false,
    alreadyAdded: false,
    fieldReadyWork: false,
    noStartTime: false,
    noHaltTime: false,
    noHaltStart: false,
    chosenValue: ""
  }
];
