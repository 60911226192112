import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    "& label": {
      color: "white",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "#64b5f6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiSelect-icon": {
      color: "white",
    },
  },
});

const BasicInfo2 = ({ info, dispatchInfo }) => {
  const classes = useStyles();

  const handleInfoChange = (event, type, stateType) => {
    dispatchInfo({
      type: type,
      value: stateType === "year" ? event : event.target.value,
      stateOption: stateType,
    });
  };
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
        flexDirection: "column",
        flexWrap: "wrap",
      }}
    >
      <FormControl
        variant="outlined"
        style={{ marginTop: 20 }}
        classes={{
          root: classes.root,
        }}
      >
        <InputLabel ref={inputLabel} htmlFor="month">
          Mjesec
        </InputLabel>
        <Select
          variant="outlined"
          labelWidth={labelWidth}
          value={info.month}
          onChange={(event) => handleInfoChange(event, "UPDATE_INFO", "month")}
          inputProps={{
            name: "month",
            id: "month",
          }}
        >
          <MenuItem value={"January"}>Siječanj</MenuItem>
          <MenuItem value={"February"}>Veljača</MenuItem>
          <MenuItem value={"March"}>Ožujak</MenuItem>
          <MenuItem value={"April"}>Travanj</MenuItem>
          <MenuItem value={"May"}>Svibanj</MenuItem>
          <MenuItem value={"June"}>Lipanj</MenuItem>
          <MenuItem value={"July"}>Srpanj</MenuItem>
          <MenuItem value={"August"}>Kolovoz</MenuItem>
          <MenuItem value={"September"}>Rujan</MenuItem>
          <MenuItem value={"October"}>Listopad</MenuItem>
          <MenuItem value={"November"}>Studeni</MenuItem>
          <MenuItem value={"December"}>Prosinac</MenuItem>
        </Select>
      </FormControl>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          inputVariant="outlined"
          style={{ marginTop: 20 }}
          views={["year"]}
          label="Godina"
          value={info.year}
          onChange={(date) => handleInfoChange(date, "UPDATE_INFO", "year")}
          classes={{
            root: classes.root,
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};
export default React.memo(BasicInfo2);
