* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  margin-bottom: 30px;
}
.nav {
  display: flex;
  ul {
    list-style: none;
    display: flex;
    li {
      margin: 15px;
      a {
        color: #0d47a1;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        text-decoration: none;
        font-size: 18px;
      }
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.title {
  a {
    color: black;
    text-decoration: none;
  }
}
