import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import bckg from "../../papyrus.png";
import "./MainPage.scss";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 150,
    width: 350,
    margin: 25,
    padding: 10,
    textAlign: "center",
    color: "white",
    background: "linear-gradient(to right, #ad5389, #3c1053)",
    boxShadow: "8px 8px 16px #d5d5d5, -8px -8px 16px #f1f1f1",
    "&:hover": {
      boxShadow: "10px 10px 85px -50px rgba(0,0,0,0.75)",
    },
  },
  control: {
    padding: theme.spacing(2),
  },
  button: {
    marginTop: 30,
    /* color: "#EDEDED ", 
    background: "linear-gradient(to right, #ad5389, #3c1053)  " */
  },
}));
const MainPage = ({ setIsRoot }) => {
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.background = `url(${bckg})`;
    return () => {
      document.body.style.background = "white";
    };
  }, []);
  return (
    <Container maxWidth="md">
      <Grid container className={"root"} spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <Paper className={classes.paper}>
                <Typography variant="h6">PDV Identifikacijski broj</Typography>
                <Button
                  className={classes.button}
                  variant="contained"
                  component={RouterLink}
                  to="/pdvid"
                >
                  Kreni
                </Button>
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <Typography variant="h6">Porezna podaci</Typography>
                <Button
                  className={classes.button}
                  variant="contained"
                  component={RouterLink}
                  to="/pdvPodaci"
                >
                  Kreni
                </Button>
              </Paper>
            </Grid>
          </Grid>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <Paper className={classes.paper}>
                <Typography variant="h6">Prijava činjenica</Typography>
                <Button
                  className={classes.button}
                  variant="contained"
                  component={RouterLink}
                  to="/cinjenice"
                >
                  Kreni
                </Button>
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <Typography variant="h6">Evidencija</Typography>
                <Button
                  className={classes.button}
                  variant="contained"
                  component={RouterLink}
                  to="/timesheet"
                >
                  Kreni
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MainPage;
