import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { Formik, Field, Form, ErrorMessage } from "formik";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import style from "./pdvID.module.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorFocus from "../ErrorFocus/ErrorFocus";

const validation = Yup.object().shape({
  oib: Yup.string()
    .min(11, "OIB mora sadržavati 11 znamenaka")
    .max(11, "OIB mora sadržavati 11 znamenaka"),
});

const pdvID = () => {
  const input = [
    { podrucni_ured: "Područni ured: " },
    { ispostava: "Ispostava: " },
    { ime_prezime: "Ime i prezime fizičke / pravne osobe: " },
    { oib: "OIB: " },
    { adresa: "Adresa (sjedište, posl. prostori..): " },
    { osnivaci_pravne_osobe: "Osnivači pravne osobe: " },
    { djelatnost: "Djelatnost: " },
    { odgovorna_osoba: "Odgovorna osoba/ opunomoćenik: " },
    { kontakt: "Kontakt (mail, tel): " },
    { osnivac_pravne_osobe2: "Osnivači pravne osobe: " },
    { razlog_trazenja_pdvid: "Razlog traženja PDV identifikacijskog broja: " },
    {
      poslovne_jedinice_povezana_drustva:
        "Poslovne jedinice / povezana društva u RH i inozemstvu (naziv, adresa, OIB): ",
    },
    {
      djelatnost_poslovne_jedinice:
        "Djelatnost poslovne jedinice / povezanog društva u RH i inozemstvu: ",
    },
    { vrste_isporuka: "Vrste isporuka: " },
    { vodenje_knjigovodstva: "Vođenje knjigovodstva: " },
    { mjesto_datum: "Mjesto, datum podnošenja: " },
  ];
  function expand(obj) {
    var keys = Object.keys(obj);
    for (var i = 0; i < keys.length; ++i) {
      var key = keys[i],
        subkeys = key.split(/,\s?/),
        target = obj[key];
      delete obj[key];
      subkeys.forEach(function (key) {
        obj[key] = target;
      });
    }
    return obj;
  }
  const inputs = (name, id, value, onChange) =>
    expand({
      "podrucni_ured, ispostava, ime_prezime, odgovorna_osoba": (
        <TextField
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          fullWidth={true}
          style={{ maxWidth: 410, marginTop: 0 }}
          margin="normal"
          variant="outlined"
        />
      ),
      oib: (
        <TextField
          name={name}
          id={id}
          value={value}
          type="number"
          onChange={onChange}
          fullWidth={true}
          style={{ maxWidth: 410, marginTop: 0 }}
          margin="normal"
          variant="outlined"
        />
      ),

      "adresa, osnivaci_pravne_osobe, djelatnost, kontakt, osnivac_pravne_osobe2, razlog_trazenja_pdvid, poslovne_jedinice_povezana_drustva, djelatnost_poslovne_jedinice, vrste_isporuka, vodenje_knjigovodstva, mjesto_datum":
        (
          <TextField
            name={name}
            id={id}
            value={value}
            onChange={onChange}
            multiline
            rows="4"
            fullWidth={true}
            style={{ maxWidth: 410, marginTop: 0 }}
            margin="normal"
            variant="outlined"
          />
        ),
    });
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Typography
        variant="h5"
        style={{ textAlign: "center", marginBottom: 40 }}
      >
        Upitnik za dodjelu PDV identifikacijskog broja
      </Typography>
      <main className={style.wrapper}>
        <Formik
          initialValues={{
            name: "pdvID",
            podrucni_ured: "",
            ispostava: "",
            ime_prezime: "",
            oib: "",
            adresa: "",
            osnivaci_pravne_osobe: "",
            djelatnost: "",
            odgovorna_osoba: "",
            kontakt: "",
            osnivac_pravne_osobe2: "",
            razlog_trazenja_pdvid: "",
            poslovne_jedinice_povezana_drustva: "",
            djelatnost_poslovne_jedinice: "",
            vrste_isporuka: "",
            vodenje_knjigovodstva: "",
            mjesto_datum: "",
          }}
          validationSchema={validation}
          onSubmit={(values, actions) => {
            axios
              .post("https://pdf-api.accountant.hr/create-pdf/", values, {
                responseType: "arraybuffer",
              })
              .then((res) => {
                const blob = new Blob([res.data], { type: "application/pdf" });
                saveAs(blob, "pdv_id_broj.pdf");
              })
              .then(() => {
                actions.setSubmitting(false);
              })

              .catch((e) => alert(e));
          }}
          render={({ status, isSubmitting }) => (
            <Form className={style.form}>
              {input.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          <label htmlFor={key}>
                            <Typography>{item[key]}</Typography>
                          </label>

                          <Field
                            rows="3"
                            cols="50"
                            name={key}
                            render={({ field, form }) => {
                              return inputs(
                                field.name,
                                field.id,
                                field.value,
                                field.onChange
                              )[field.name];
                            }}
                          />
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              {status && status.msg && <div>{status.msg}</div>}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                Spremi PDF
              </Button>
              {isSubmitting ? (
                <CircularProgress style={{ marginTop: 20, marginBottom: 40 }} />
              ) : null}
              <ErrorFocus />
            </Form>
          )}
        />
      </main>
    </>
  );
};

export default pdvID;
