import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { Formik, FastField, Field, Form, ErrorMessage } from "formik";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ErrorFocus from "../ErrorFocus/ErrorFocus";
import * as Yup from "yup";
import style from "./pdvPodaci.module.scss";
import {
  input,
  input1,
  input2,
  input3,
  input4,
  input5,
  input6,
  input7,
  input8,
  input9,
  input10,
  input11,
  input12,
  input13,
  input14,
  input15,
  input16,
  input17,
  input18,
  input19,
  initValues,
} from "../../constants/index";
import { b64toBlob } from "../../functions";

const validation = Yup.object().shape({
  oib: Yup.string()
    .min(11, "OIB mora sadržavati 11 znamenaka")
    .max(11, "OIB mora sadržavati 11 znamenaka"),
});
function expand(obj) {
  var keys = Object.keys(obj);
  for (var i = 0; i < keys.length; ++i) {
    var key = keys[i],
      subkeys = key.split(/,\s?/),
      target = obj[key];
    delete obj[key];
    subkeys.forEach(function (key) {
      obj[key] = target;
    });
  }
  return obj;
}
const inputs = (name, id, value, onChange, setFieldValue) =>
  expand({
    "nazivOsobe, poslovneJediniceNaziv, racuni, racuniDevizni, ": (
      <TextField
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        fullWidth={true}
        style={{ maxWidth: 410, marginTop: 0 }}
        margin="normal"
        variant="outlined"
      />
    ),
    "oib, maticniBroj, brojTel, osnivacTel, ovlastenaTel, opunomocenikTel, vodenjeSamostalnoTel, vodenjeKnjigovodstvoTel, vodenjeKnjigovodstvoTel, osobaKnjigeTel, unajmljeniProstorUkupni, poslovneJediniceTel, poslovneJediniceVlastitiProstorUkupna, poslovneJediniceUnajmljeniProstor, brojRadnika, brojRadnikaRadniOdnos, brojRadnikaInozemnih, brojRadnikaSezonskih, brojRadnikaUcenika, brojRadnikaOstalih":
      (
        <TextField
          name={name}
          id={id}
          value={value}
          type="number"
          onChange={onChange}
          fullWidth={true}
          style={{ maxWidth: 410, marginTop: 0 }}
          margin="normal"
          variant="outlined"
        />
      ),

    "adresa, racuniNazivAdresa, osnivacImeOib, osnivacAdresa, osnivacPutovnica, ovlastenaIme, ovlastenaAdresa, ovlastenaPutovnica, opunomocenikIme, opunomocenikAdresa, opunomocenikPutovnica, vodenjeSamostalnoIme, vodenjeKnjigovodstvoNaziv, vodenjeKnjigovodstvoAdresa, vodenjeKnjigovodstvoNaziv, vodenjeKnjigovodstvoAdresa, osobaKnjigeIme, unajmljeniProstorNajmodavacIme, unajmljeniProstorNajmodavacAdresa, poslovneJediniceAdresa, poslovneJediniceDjelatnost, ":
      (
        <TextField
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          multiline
          rows="4"
          fullWidth={true}
          style={{ maxWidth: 410, marginTop: 0 }}
          margin="normal"
          variant="outlined"
        />
      ),

    "email, vodenjeSamostalnoEmail, vodenjeKnjigovodstvoEmail, osobaKnjigeEmail":
      (
        <TextField
          name={name}
          id={id}
          value={value}
          type="email"
          onChange={onChange}
          fullWidth={true}
          style={{ maxWidth: 410, marginTop: 0 }}
          margin="normal"
          variant="outlined"
        />
      ),
    "datumUpisaURegistar, datumPocetkaObavljanja, ": (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          format="dd.MM.yyyy"
          inputVariant="outlined"
          value={value}
          onChange={(value) => setFieldValue(name, value)}
        />
      </MuiPickersUtilsProvider>
    ),
  });
const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <>
      <Radio
        name={name}
        id={id}
        type="radio"
        value={id}
        checked={id === value}
        onChange={onChange}
        className={className}
        color="primary"
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </>
  );
};
//CHECK ON CHANGE UPDATE SPEED

const pdvPodaci = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Typography
        variant="h5"
        style={{ textAlign: "center", marginBottom: 40 }}
      >
        Podaci o poreznom obvezniku
      </Typography>
      <main className={style.wrapper}>
        <Formik
          initialValues={initValues}
          validationSchema={validation}
          onSubmit={(values, actions) => {
            axios
              .post("https://pdf-api.accountant.hr/create-pdf/", values)
              .then((res) => {
                const blob = b64toBlob(res.data, "application/pdf");

                saveAs(blob, "pdv_podaci.pdf");
              })
              .then(() => {
                actions.setSubmitting(false);
              })

              .catch((e) => alert(e));
          }}
          render={({ status, isSubmitting }) => (
            <Form className={style.form}>
              {input.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => (
                                  <TextField
                                    name={field.name}
                                    id={field.id}
                                    value={field.value}
                                    onChange={field.onChange}
                                    fullWidth={true}
                                    style={{ maxWidth: 410, marginTop: 0 }}
                                    margin="normal"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h5" className={style.margins}>
                I OPĆI PODACI O POREZNOM OBVEZNIKU
              </Typography>
              {input1.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      item["ustrojstveniOblik"] !== undefined
                        ? style.radio
                        : style.formItem
                    }
                  >
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {key === "ustrojstveniOblik" ? (
                            <div>
                              <div>
                                <Typography style={{ marginBottom: 20 }}>
                                  2. Ustrojstveni oblik upisa u sudski registar:
                                </Typography>
                                <Field
                                  component={RadioButton}
                                  name={key}
                                  id={"društvo s ograničenom odgovornošću"}
                                  label={"društvo s ograničenom odgovornošću"}
                                  className={"radioButton"}
                                />
                              </div>
                              <div>
                                <Field
                                  component={RadioButton}
                                  name={key}
                                  id={"dioničko društvo"}
                                  label={"dioničko društvo"}
                                  className={"radioButton"}
                                />
                              </div>

                              <div>
                                <Field
                                  component={RadioButton}
                                  name={key}
                                  id={
                                    "jednostavno društvo s ograničenom odgovornošću"
                                  }
                                  label={
                                    "jednostavno društvo s ograničenom odgovornošću"
                                  }
                                  className={"radioButton"}
                                />
                              </div>
                              <div>
                                <Field
                                  component={RadioButton}
                                  name={key}
                                  id={"udruga"}
                                  label={"udruga"}
                                  className={"radioButton"}
                                />
                              </div>
                              <div>
                                <Field
                                  component={RadioButton}
                                  name={key}
                                  id={"ustanova"}
                                  label={"ustanova"}
                                  className={"radioButton"}
                                />
                              </div>
                              <div>
                                <Field
                                  component={RadioButton}
                                  name={key}
                                  id={"drugo"}
                                  label={"drugo"}
                                  className={"radioButton"}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => {
                                  return inputs(
                                    field.name,
                                    field.id,
                                    field.value,
                                    field.onChange
                                  )[field.name];
                                }}
                              />
                            </>
                          )}
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h5" className={style.margins}>
                II PODACI O DRUŠTVU
              </Typography>
              <div className={style.formItem}>
                <label htmlFor="brojClanova">
                  <Typography>9. Broj članova društva:</Typography>
                </label>
                <FastField
                  rows="3"
                  cols="50"
                  name="brojClanova"
                  render={({ field, form }) => (
                    <TextField
                      name={field.name}
                      id={field.id}
                      value={field.value}
                      onChange={field.onChange}
                      type="number"
                      fullWidth={true}
                      style={{ maxWidth: 410, marginTop: 0 }}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <Typography variant="h6" className={style.margins}>
                10. OSNIVAČ DRUŠTVA:
              </Typography>
              {input2.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => {
                                  return inputs(
                                    field.name,
                                    field.id,
                                    field.value,
                                    field.onChange
                                  )[field.name];
                                }}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h6" className={style.margins}>
                11. OSOBA OVLAŠTENA ZA ZASTUPANJE
              </Typography>
              {input3.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => {
                                  return inputs(
                                    field.name,
                                    field.id,
                                    field.value,
                                    field.onChange
                                  )[field.name];
                                }}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h6" className={style.margins}>
                12. PODACI O OPUNOMOĆENIKU / PROKURISTU
              </Typography>
              {input4.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => {
                                  return inputs(
                                    field.name,
                                    field.id,
                                    field.value,
                                    field.onChange
                                  )[field.name];
                                }}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h5" className={style.margins}>
                III VOĐENJE POSLOVNIH KNJIGA
              </Typography>
              <Typography variant="h6" className={style.margins}>
                13. Samostalno
              </Typography>
              {input5.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => {
                                  return inputs(
                                    field.name,
                                    field.id,
                                    field.value,
                                    field.onChange
                                  )[field.name];
                                }}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <div className={style.margins}>
                <Typography variant="h6">14. Knjigovodstveni servis</Typography>
                <Typography variant="h6" style={{ marginTop: 20 }}>
                  Podaci o knjigovodstvenom servisu:
                </Typography>
              </div>
              {input6.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => {
                                  return inputs(
                                    field.name,
                                    field.id,
                                    field.value,
                                    field.onChange
                                  )[field.name];
                                }}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h6" className={style.margins}>
                Podaci o osobi koja vodi poslovne knjige:
              </Typography>
              {input7.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => {
                                  return inputs(
                                    field.name,
                                    field.id,
                                    field.value,
                                    field.onChange
                                  )[field.name];
                                }}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h5" className={style.margins}>
                IV DJELATNOST POREZNOG OBVEZNIKA
              </Typography>
              <div className={style.formItem}>
                <label htmlFor="datumUpisaURegistar">
                  <Typography>15. DATUM UPISA U SUDSKI REGISTAR:</Typography>
                </label>
                <FastField
                  rows="3"
                  cols="50"
                  name="datumUpisaURegistar"
                  render={({ field, form }) => {
                    return inputs(
                      field.name,
                      field.id,
                      field.value,
                      field.onChange,
                      form.setFieldValue
                    )[field.name];
                  }}
                />
              </div>
              <div className={style.formItem}>
                <label htmlFor="datumPocetkaObavljanja">
                  <Typography>
                    16. DATUM POČETKA OBAVLJANJA DJELATNOSTI:
                  </Typography>
                </label>
                <FastField
                  rows="3"
                  cols="50"
                  name="datumPocetkaObavljanja"
                  render={({ field, form }) => {
                    return inputs(
                      field.name,
                      field.id,
                      field.value,
                      field.onChange,
                      field.onChange
                    )[field.name];
                  }}
                />
              </div>
              <Typography variant="h6" className={style.margins}>
                17. DJELATNOST KOJA SE OBAVLJA: OPISATI ČIME SE POBLIŽE BAVI:
              </Typography>
              {input8.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => (
                                  <TextField
                                    name={field.name}
                                    id={field.id}
                                    value={field.value}
                                    onChange={field.onChange}
                                    multiline
                                    rows="4"
                                    fullWidth={true}
                                    style={{ maxWidth: 410, marginTop: 0 }}
                                    margin="normal"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h6" className={style.margins}>
                18. OBAVLJANJE DJELATNOSTI
              </Typography>
              <div className={style.formItem}>
                <label htmlFor="obavljanjeDjelatnostiSjediste">
                  <Typography> Sjedište:</Typography>
                </label>
                <FastField
                  rows="3"
                  cols="50"
                  name="obavljanjeDjelatnostiSjediste"
                  render={({ field, form }) => (
                    <TextField
                      name={field.name}
                      id={field.id}
                      value={field.value}
                      onChange={field.onChange}
                      multiline
                      rows="4"
                      fullWidth={true}
                      style={{ maxWidth: 410, marginTop: 0 }}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <Typography variant="h6" className={style.margins}>
                Vlastiti prostor:
              </Typography>
              <div className={style.formItem}>
                <label htmlFor="obavljanjeDjelatnostiUkupni">
                  <Typography> Ukupna veličina prostora (m2):</Typography>
                </label>
                <FastField
                  rows="3"
                  cols="50"
                  name="obavljanjeDjelatnostiUkupni"
                  render={({ field, form }) => (
                    <TextField
                      name={field.name}
                      id={field.id}
                      value={field.value}
                      onChange={field.onChange}
                      type="number"
                      fullWidth={true}
                      style={{ maxWidth: 410, marginTop: 0 }}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <Typography variant="h6" className={style.margins}>
                Unajmljeni prostor:
              </Typography>
              {input10.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => {
                                  return inputs(
                                    field.name,
                                    field.id,
                                    field.value,
                                    field.onChange
                                  )[field.name];
                                }}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h6" className={style.margins}>
                19. POSLOVNE JEDINICE I PRODAJNA MJESTA
              </Typography>
              {input11.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => {
                                  return inputs(
                                    field.name,
                                    field.id,
                                    field.value,
                                    field.onChange
                                  )[field.name];
                                }}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h6" className={style.margins}>
                Prostor u kojem se djelatnost poslovne jedinice i prodajnog
                mjesta obavlja
              </Typography>
              {input12.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => {
                                  return inputs(
                                    field.name,
                                    field.id,
                                    field.value,
                                    field.onChange
                                  )[field.name];
                                }}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h6" className={style.margins}>
                Podaci o najmodavcu:
              </Typography>
              {input13.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => (
                                  <TextField
                                    name={field.name}
                                    id={field.id}
                                    value={field.value}
                                    onChange={field.onChange}
                                    multiline
                                    rows="4"
                                    fullWidth={true}
                                    style={{ maxWidth: 410, marginTop: 0 }}
                                    margin="normal"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <div className={style.margins}>
                <Typography variant="h5">V PODACI O RADNICIMA</Typography>
                <Typography style={{ marginTop: 10 }} variant="h6">
                  20. PODACI O RADNICIMA
                </Typography>
              </div>
              {input14.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => {
                                  return inputs(
                                    field.name,
                                    field.id,
                                    field.value,
                                    field.onChange
                                  )[field.name];
                                }}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <div className={style.margins}>
                <Typography variant="h5">VI DUGOTRAJNA IMOVINA</Typography>
                <Typography
                  variant="h6"
                  style={{ fontSize: 16 }}
                  className={style.margins}
                >
                  21. OPIS NAJZNAČAJNIJE DUGOTRAJE IMOVINE KOJA SE KORISTI ZA
                  OBAVLJANJE DJELATNOSTI
                </Typography>
                <Typography variant="h6">Materijalna imovina</Typography>
              </div>
              {input15.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => (
                                  <TextField
                                    name={field.name}
                                    id={field.id}
                                    value={field.value}
                                    onChange={field.onChange}
                                    multiline
                                    rows="4"
                                    fullWidth={true}
                                    style={{ maxWidth: 410, marginTop: 0 }}
                                    margin="normal"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h6" className={style.margins}>
                Podaci o vlasniku unajmljene imovine:
              </Typography>
              {input16.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => (
                                  <TextField
                                    name={field.name}
                                    id={field.id}
                                    value={field.value}
                                    onChange={field.onChange}
                                    multiline
                                    rows="4"
                                    fullWidth={true}
                                    style={{ maxWidth: 410, marginTop: 0 }}
                                    margin="normal"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h6" className={style.margins}>
                Nematerijalna imovina
              </Typography>
              {input17.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => (
                                  <TextField
                                    name={field.name}
                                    id={field.id}
                                    value={field.value}
                                    onChange={field.onChange}
                                    multiline
                                    rows="4"
                                    fullWidth={true}
                                    style={{ maxWidth: 410, marginTop: 0 }}
                                    margin="normal"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography variant="h6" className={style.margins}>
                Podaci o vlasniku unajmljene imovine:
              </Typography>
              {input18.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => (
                                  <TextField
                                    name={field.name}
                                    id={field.id}
                                    value={field.value}
                                    onChange={field.onChange}
                                    multiline
                                    rows="4"
                                    fullWidth={true}
                                    style={{ maxWidth: 410, marginTop: 0 }}
                                    margin="normal"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography
                variant="h6"
                style={{ textAlign: "center" }}
                className={style.margins}
              >
                22. POPIS DUGOTRAJNE IMOVINE U VLASNIŠTVU POREZNOG OBVEZNIKA
                KOJA JE DANA U NAJAM
              </Typography>
              <Typography variant="h6" style={{ marginBottom: 40 }}>
                Podaci o vlasniku unajmljene imovine:
              </Typography>
              {input19.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          {
                            <>
                              <label htmlFor={key}>
                                <Typography>{item[key]}</Typography>
                              </label>
                              <FastField
                                name={key}
                                render={({ field, form }) => (
                                  <TextField
                                    name={field.name}
                                    id={field.id}
                                    value={field.value}
                                    onChange={field.onChange}
                                    multiline
                                    rows="4"
                                    fullWidth={true}
                                    style={{ maxWidth: 410, marginTop: 0 }}
                                    margin="normal"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </>
                          }
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              {status && status.msg && <div>{status.msg}</div>}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                Spremi PDF
              </Button>
              {isSubmitting ? (
                <CircularProgress style={{ marginTop: 20, marginBottom: 40 }} />
              ) : null}
              <ErrorFocus />
            </Form>
          )}
        />
      </main>
    </>
  );
};

export default pdvPodaci;
