import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter
} from "react-router-dom";
import style from "./App.module.scss";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import PdvID from "../PdvID/PdvID";
import PdvPodaci from "../PdvPodaci/PdvPodaci";
import CinjeniceOporez from "../CinjeniceOporez/CinjeniceOporez";
import WorkTimesheet from "../WorkTimesheet/WorkTimesheet";
import MainPage from "../MainPage/MainPage";
import bckg from "../../webb.png";
function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
const location = window.location.pathname;
const App = props => {
  return (
    <Router>
      <div>
        <HideOnScroll {...props}>
          <AppBar style={{ backgroundColor: "#E5E5E5" }}>
            <Container>
              <Toolbar className={style.nav} variant="dense">
                <Typography variant="h6" className={style.title}>
                  <Link to="/">Početna</Link>
                </Typography>
              </Toolbar>
            </Container>
          </AppBar>
        </HideOnScroll>
        <Container
          style={{ marginTop: 70 }}
          maxWidth={location.match(/timesheet/) ? "xl" : null}
        >
          <Box>
            <Route
              path="/"
              exact
              render={props => <MainPage {...props}></MainPage>}
            />
            <Route
              path="/pdvid"
              render={props => <PdvID {...props}></PdvID>}
            ></Route>
            <Route
              path="/pdvPodaci/"
              render={props => <PdvPodaci {...props}></PdvPodaci>}
            />
            <Route
              path="/cinjenice"
              render={props => <CinjeniceOporez {...props}></CinjeniceOporez>}
            />
            <Route
              path="/timesheet"
              render={props => <WorkTimesheet {...props}></WorkTimesheet>}
            />
          </Box>
        </Container>
      </div>
    </Router>
  );
};

export default App;
