import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    "& label": {
      color: "white",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "#64b5f6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
    },
  },
});

const BasicInfo = ({ info, dispatchInfo }) => {
  const classes = useStyles();

  const handleInfoChange = (event, type, stateType) => {
    dispatchInfo({
      type: type,
      value: stateType === "year" ? event : event.target.value,
      stateOption: stateType,
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
        flexDirection: "column",
        flexWrap: "wrap",
      }}
    >
      <TextField
        label="Tvrtka - naziv"
        variant="outlined"
        value={info.company}
        onChange={() => handleInfoChange(event, "UPDATE_INFO", "company")}
        classes={{
          root: classes.root, // class name, e.g. `classes-nesting-root-x`
        }}
      />
      <TextField
        label="Prezime i ime radnika"
        style={{ marginTop: 20 }}
        variant="outlined"
        value={info.fullName}
        onChange={() => handleInfoChange(event, "UPDATE_INFO", "fullName")}
        classes={{
          root: classes.root, // class name, e.g. `classes-nesting-root-x`
        }}
      />
    </div>
  );
};
export default React.memo(BasicInfo);
