import React from "react";
import TimesheetTable from "../TimesheetTable/TimesheetTable";
import BasicInfo from "../BasicInfo/BasicInfo";
import BasicInfo2 from "../BasicInfo2/BasicInfo2";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  initialDays,
  valuesToChoose,
  choosenValuesEnum,
  months,
  monthsNum,
  monthHours,
} from "../../constants/index";
import saveAs from "file-saver";
import style from "./WorkTimesheet.module.scss";
import axios from "axios";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import hrLocale from "date-fns/locale/hr";
import { isAfter } from "date-fns";

/* let fieldWorkFlag = false;
let alreadyAdded = false;
let fieldReadyWork = false;
let noStartTime = false;
let noHaltTime = false;
let noHaltStart = false; */

const toHHMM = (num) => {
  var sec_num = parseInt(num, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.round((sec_num - hours * 3600) / 60);
  if (minutes === 60) {
    minutes = 0;
    hours++;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return hours + ":" + minutes;
};
function correctFormat(input) {
  let output = input;
  if (input < 10) {
    output = "0" + input;
  }
  return output;
}
function diff_hours(dt2, dt1) {
  let diff = Math.abs(dt2.getTime() - dt1.getTime()) / 1000;
  return toHHMM(diff);
}
function getHoursFromString(string) {
  return Number(string.substr(0, string.indexOf(":")));
}
function addTimes(arr) {
  let hours = 0;
  let minutes = 0;
  arr.map((item) => {
    if (item === null || item === "" || item === undefined) {
      return 0;
    }
    hours += Number(item.substr(0, item.indexOf(":")));
    minutes += Number(item.substr(item.indexOf(":") + 1));
    if (minutes >= 60) {
      hours++;
      let mins = minutes - 60;
      minutes = mins;
    }
  });
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
}

function getDate(date) {
  return new Date(`12 December 2018 ${date}`);
}
function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const daysReducer = (state, action) => {
  const sum = state.reduce(
    (sum, day) =>
      (sum += Number(
        day.totalWork !== null
          ? day.totalWork.substr(0, day.totalWork.indexOf(":"))
          : 0
      )),
    0
  );
  let extraFlag = false;
  switch (action.type) {
    case "SET_CHOSEN":
      return state.map((day) => {
        if (day.id === action.id) {
          return { ...day, chosenValue: action.chosen };
        } else {
          return day;
        }
      });
    case "TEST":
      return action.state;
    case "DELETE_VALUE":
      return state.map((day) => {
        if (day.id === action.id) {
          return { ...day, [action.stateOption]: null };
        } else {
          return day;
        }
      });
    case "COPY_LAST":
      const OBJ = {};
      Object.assign(OBJ, state[Number(action.id) - 2]);
      delete OBJ.id;

      Object.assign(state[Number(action.id) - 1], OBJ);

      return state;
    /*    return state.map(day => {
        if (Number(day.id) === Number(action.id) - 1) {
          console.log(day);
          return day;
        } else {
          return day;
        }
      }); */
    case "SET_STATE":
      return state.map((day) => {
        const monthTime = monthHours[action.year + action.month];
        if (day.id === action.id) {
          /*  if (day.totalWork !== null) {
            return { ...day, fieldReadyWork: true };
          } */
          /*  if (!isValidDate(action.value)) {
            return {
              ...day,
              [action.stateOption]: null
            };
          } */
          if (action.stateOption === "fieldWork") {
            if (action.value === null) {
              /* fieldReadyWork = false; */
              return { ...day, fieldWork: null };
            }
            if (
              action.value.getHours() > getDate(day.totalWork).getHours() ||
              day.totalWork === null
            ) {
              return { ...day, fieldWork: "undefined", fieldWorkFlag: true };
            } else {
              return {
                ...day,
                fieldWork: action.value,
                fieldWorkFlag: false,
                fieldReadyWork: true,
              };
            }
            /* {
              fieldWorkFlag = true;
              return { ...day, fieldWork: "undefined" };
            } */
          }
          if (action.stateOption === "readyHours") {
            if (action.value === null) {
              /*  fieldReadyWork = false; */
              return { ...day, readyHours: null };
            }
            if (
              action.value.getHours() > getDate(day.totalWork).getHours() ||
              day.totalWork === null
            ) {
              return { ...day, readyHours: "undefined", fieldWorkFlag: true };
            } else {
              return {
                ...day,
                readyHours: action.value,
                fieldWorkFlag: false,
                fieldReadyWork: true,
              };
            }
            /* {
              fieldWorkFlag = true;
              return { ...day, readyHours: "undefined" };
            } */
          }

          if (action.stateOption === "haltEnd") {
            if (!isValidDate(day.haltStart) && action.value !== null) {
              return {
                ...day,
                haltEnd: new Date("undefined"),
                noHaltTime: true,
              };
            } /* else if (isValidDate(day.haltStart) && action.value === null) {
              console.log("is valid date but action value is null")
              return {
                ...day,
                noHaltTime: false
              };
            } */

            if (day.fieldReadyWork) {
              return { ...day, alreadyAdded: false };
            }
            if (action.value === null) {
              if (day.totalWork !== null) {
                return { ...day, haltEnd: null /* fieldReadyWork: true */ };
              } else {
                action.value = new Date("undefined");

                return {
                  ...day,
                  haltEnd: null,
                  totalWork: null,
                  dayWork: null,
                  dayHoliday: null,
                  dayExtra: null,
                  nightWork: null,
                  nightHoliday: null,
                  nightExtra: null,
                  fieldReadyWork: false,
                };
              }
            }
            const nightHalt =
              action.value.getHours() >= 0 && action.value.getHours() <= 6
                ? addDays(action.value, 1)
                : action.value;
            const nightEnd =
              day.workEnd.getHours() >= 0 &&
              day.workEnd.getHours() < day.workStart.getHours()
                ? addDays(day.workEnd, 1)
                : day.workEnd;
            let dayTEnd = new Date(day.workStart.getTime());
            dayTEnd.setHours(22);
            dayTEnd.setMinutes(0);
            let times = [];
            let dayWorkArray = [];
            if (
              action.value.getHours() >= 22 ||
              action.value.getHours() <= 6 ||
              day.workEnd.getHours() >= 22 ||
              day.workEnd.getHours() <= 6
            ) {
              const realStartHalt =
                day.haltStart.getHours() >= 0 &&
                day.haltStart.getHours() < day.workStart.getHours()
                  ? addDays(day.haltStart, 1)
                  : day.haltStart;
              times = [
                diff_hours(
                  day.haltStart.getHours() >= 0 ? realStartHalt : day.haltStart,
                  day.workStart
                ),
                diff_hours(nightHalt, nightEnd),
              ];
              dayWorkArray = [
                sum < monthTime
                  ? action.value.getHours() < 22 && action.value.getHours() > 6
                    ? diff_hours(
                        action.value,
                        day.haltStart.getTime() < 22 ? day.haltStart : dayTEnd
                      )
                    : "00:00"
                  : "",
                diff_hours(
                  day.workStart,
                  day.haltStart.getHours() >= 0 ? realStartHalt : day.haltStart
                ),
              ];
            } else {
              times = [
                diff_hours(day.haltStart, day.workStart),
                diff_hours(
                  action.value,
                  day.workEnd < day.workStart
                    ? addDays(day.workEnd, 1)
                    : day.workEnd
                ),
              ];
              dayWorkArray = [
                diff_hours(
                  action.value,
                  day.workEnd.getHours() < day.workStart.getHours()
                    ? addDays(day.workEnd, 1)
                    : day.workEnd
                ),
                diff_hours(day.workStart, day.haltStart),
              ];
            }
            const totalWork = addTimes(times);
            const overTime = diff_hours(getDate(totalWork), getDate("08:00"));
            const otherDayWork =
              action.value.getHours() < 22 && action.value > day.haltStart
                ? diff_hours(dayTEnd, action.value)
                : diff_hours(day.workStart, day.haltStart);
            const totalDayWork = //change this to get correct time
              (day.workEnd.getHours() > 22 && day.workEnd.getHours() <= 6) ||
              (day.haltStart.getHours() <= 6 && day.haltStart.getHours() >= 22)
                ? diff_hours(
                    day.workStart,
                    day.haltStart.getHours() < 22 && //if haltstart is less then 22H
                      day.haltStart.getHours() < day.workEnd.getHours() //if haltstart is less then day work end then diff with 22
                      ? dayTEnd
                      : day.haltStart
                  )
                : addTimes(dayWorkArray);
            console.log(dayWorkArray, totalDayWork);
            const actualDayWork =
              otherDayWork !== 0
                ? day.haltStart.getHours() >= 22 ||
                  action.value.getHours() >= 22
                  ? totalDayWork
                  : addTimes([otherDayWork, totalDayWork])
                : "00:00";
            const totalNightWork = diff_hours(
              getDate(totalWork),
              getDate(actualDayWork)
            );
            const dayOvertime = diff_hours(
              getDate(actualDayWork),
              getDate("08:00")
            );

            if (action.sundays.includes(Number(day.id))) {
              if (sum > monthTime) {
                console.log("haltend", sum);
                extraFlag = monthTime - sum < 0 && true;
                const diff = monthTime - sum;
                // const specialDayWorkTime = day.haltStart.getHours() > 22 ? Math.abs(dayTEnd - workStart) / 36e5 :
                const modDayWork =
                  totalWork !== "NaN:NaN"
                    ? `${getHoursFromString(totalWork) - Math.abs(diff)}:00`
                    : "00:00";
                const modNightWork = getHoursFromString(totalNightWork) - diff;
                /*  if (extraFlag === true && diff < 0) {
                  extraFlag = false;
                } */
                const dayDifference =
                  getHoursFromString(actualDayWork) -
                  (getHoursFromString(actualDayWork) -
                    getHoursFromString(modDayWork));
                console.log({
                  diff: diff,
                  totalWork: totalWork,
                  otherDayWork: otherDayWork,
                  totalnightwork: totalNightWork,
                  totalDayWork: totalDayWork,
                  actualDayWork: actualDayWork,
                  modDayWork: modDayWork,
                  modNightWork: modNightWork,
                  totalnightwrk: getHoursFromString(totalNightWork),
                });
                return {
                  ...day,
                  haltEnd: action.value,
                  totalWork: totalWork,
                  dayExtra: extraFlag
                    ? getHoursFromString(modDayWork) <= 0
                      ? actualDayWork
                      : getHoursFromString(totalNightWork) <= 0
                      ? `${
                          correctFormat(getHoursFromString(actualDayWork)) -
                          diff
                        }:00`
                      : getHoursFromString(actualDayWork) -
                          getHoursFromString(modDayWork) <
                        0
                      ? getHoursFromString(actualDayWork) > 8
                        ? `${
                            correctFormat(getHoursFromString(actualDayWork)) - 8
                          }:00`
                        : null
                      : dayDifference > 8
                      ? `${
                          correctFormat(getHoursFromString(actualDayWork)) - 8
                        }:00`
                      : `${correctFormat(
                          getHoursFromString(actualDayWork) -
                            getHoursFromString(modDayWork)
                        )}:00`
                    : "",
                  dayHoliday: extraFlag
                    ? getHoursFromString(modDayWork) <= 0
                      ? ""
                      : getHoursFromString(actualDayWork) -
                          getHoursFromString(modDayWork) <
                        0
                      ? getHoursFromString(actualDayWork) > 8
                        ? "08:00"
                        : actualDayWork
                      : dayDifference > 8
                      ? "08:00"
                      : `${correctFormat(dayDifference)}:00` //actualmod day - moddaywork
                    : actualDayWork,
                  nightExtra: extraFlag
                    ? (day.haltStart.getHours() > 22 || action.value > 22) &&
                      day.workEnd.getHours() !== 22
                      ? getHoursFromString(modDayWork) <= 0
                        ? totalNightWork
                        : getHoursFromString(actualDayWork) >= 8 ||
                          getHoursFromString(totalWork) >= 8
                        ? totalNightWork
                        : `${correctFormat(Math.abs(diff))}:00`
                      : ""
                    : totalNightWork,
                  nightHoliday: extraFlag
                    ? getHoursFromString(totalNightWork) !== 0
                      ? getHoursFromString(modDayWork) <= 0
                        ? null
                        : getHoursFromString(totalWork) < 8 ||
                          getHoursFromString(actualDayWork) < 8
                        ? getHoursFromString(totalNightWork) - Math.abs(diff) <
                          0
                          ? null
                          : `${correctFormat(
                              getHoursFromString(totalNightWork) -
                                Math.abs(diff)
                            )}:00`
                        : null
                      : null
                    : null,
                  workExists: true,
                };
                /*  modDayWork < 0 ? null : `${modDayWork}:00`;
                modDayWork < 0
                  ? `${Math.abs(diff - modDayWork)}:00`
                  : `${Math.abs(diff)}:00`; */
              } else {
                return {
                  ...day,
                  [action.stateOption]:
                    action.value === null ? null : action.value,
                  totalWork: totalWork,
                  dayHoliday:
                    getDate(actualDayWork).getHours() > 8
                      ? "08:00"
                      : actualDayWork,
                  dayExtra:
                    getDate(totalDayWork).getHours() > 8 ? dayOvertime : null,
                  nightHoliday:
                    getDate(totalDayWork).getHours() < 8
                      ? getDate(totalWork).getHours() > 8
                        ? diff_hours(getDate(totalNightWork), getDate(overTime))
                        : totalNightWork
                      : null,
                  nightExtra:
                    getDate(totalDayWork).getHours() > 8
                      ? totalNightWork
                      : getDate(totalWork).getHours() > 8
                      ? overTime
                      : null,
                };
              }
            } else {
              if (sum > monthTime) {
                extraFlag = monthTime - sum < 0 && true;
                const diff = monthTime - sum;
                // const specialDayWorkTime = day.haltStart.getHours() > 22 ? Math.abs(dayTEnd - workStart) / 36e5 :
                const modDayWork =
                  totalWork !== "NaN:NaN"
                    ? `${getHoursFromString(totalWork) - Math.abs(diff)}:00`
                    : "00:00";
                const modNightWork = getHoursFromString(totalNightWork) - diff;
                /*  if (extraFlag === true && diff < 0) {
                  extraFlag = false;
                } */
                const dayDifference =
                  getHoursFromString(actualDayWork) -
                  (getHoursFromString(actualDayWork) -
                    getHoursFromString(modDayWork));
                console.log({
                  diff: diff,
                  totalWork: totalWork,
                  otherDayWork: otherDayWork,
                  totalnightwork: totalNightWork,
                  totalDayWork: totalDayWork,
                  actualDayWork: actualDayWork,
                  modDayWork: modDayWork,
                  modNightWork: modNightWork,
                  totalnightwrk: getHoursFromString(totalNightWork),
                  dayDifference: dayDifference,
                });
                return {
                  ...day,
                  haltEnd: action.value,
                  totalWork: totalWork,
                  dayExtra: extraFlag
                    ? getHoursFromString(modDayWork) <= 0
                      ? actualDayWork
                      : getHoursFromString(totalNightWork) <= 0
                      ? `${getHoursFromString(actualDayWork) - diff}:00`
                      : getHoursFromString(actualDayWork) -
                          getHoursFromString(modDayWork) <
                        0
                      ? getHoursFromString(actualDayWork) > 8
                        ? `${
                            correctFormat(getHoursFromString(actualDayWork)) - 8
                          }:00`
                        : null
                      : dayDifference > 8
                      ? `${
                          correctFormat(getHoursFromString(actualDayWork)) - 8
                        }:00`
                      : `${correctFormat(
                          getHoursFromString(actualDayWork) -
                            getHoursFromString(modDayWork)
                        )}:00`
                    : "",
                  dayWork: extraFlag
                    ? getHoursFromString(modDayWork) <= 0
                      ? ""
                      : getHoursFromString(actualDayWork) -
                          getHoursFromString(modDayWork) <
                        0
                      ? getHoursFromString(actualDayWork) > 8
                        ? "08:00"
                        : actualDayWork
                      : dayDifference > 8
                      ? "08:00"
                      : `${dayDifference}:00` //actualmod day - moddaywork
                    : actualDayWork,
                  nightExtra: extraFlag
                    ? (day.haltStart.getHours() > 22 || action.value > 22) &&
                      day.workEnd.getHours() !== 22
                      ? getHoursFromString(modDayWork) <= 0
                        ? totalNightWork
                        : getHoursFromString(actualDayWork) >= 8 ||
                          getHoursFromString(totalWork) >= 8
                        ? totalNightWork
                        : `${correctFormat(Math.abs(diff))}:00`
                      : ""
                    : totalNightWork,
                  nightWork: extraFlag
                    ? getHoursFromString(totalNightWork) !== 0
                      ? getHoursFromString(modDayWork) <= 0
                        ? null
                        : getHoursFromString(totalWork) < 8 ||
                          getHoursFromString(actualDayWork) < 8
                        ? getHoursFromString(totalNightWork) - Math.abs(diff) <
                          0
                          ? null
                          : `${correctFormat(
                              getHoursFromString(totalNightWork) -
                                Math.abs(diff)
                            )}:00`
                        : null
                      : null
                    : null,
                  workExists: true,
                };
              } else {
                return {
                  ...day,
                  [action.stateOption]: action.value,
                  totalWork: totalWork,
                  dayWork:
                    getDate(totalDayWork).getHours() > 8
                      ? "08:00"
                      : totalDayWork,
                  dayExtra:
                    getDate(totalDayWork).getHours() > 8 ? dayOvertime : null,
                  nightWork:
                    getDate(totalDayWork).getHours() > 8
                      ? null
                      : getDate(totalWork).getHours() > 8
                      ? diff_hours(getDate(totalNightWork), getDate(overTime))
                      : totalNightWork,
                  nightExtra:
                    getDate(totalDayWork).getHours() > 8
                      ? totalNightWork
                      : getDate(totalWork).getHours() > 8
                      ? overTime
                      : null,
                };
              }
            }
          } else {
            if (action.stateOption === "haltStart") {
              if (day.totalWork === null && action.value !== null) {
                return {
                  ...day,
                  haltStart: new Date("undefined"),
                  noHaltStart: true,
                };
              } else {
                return {
                  ...day,
                  [action.stateOption]: action.value,
                  noHaltStart: false,
                };
              }
            }
            if (action.value === null) {
              return {
                ...day,
                [action.stateOption]: action.value,
                alreadyAdded: false,
              };
            } else if (action.value !== null) {
              return {
                ...day,
                [action.stateOption]: action.value,
                alreadyAdded: true,
              };
            }
            return { ...day, [action.stateOption]: action.value };
          }
        } else {
          return day;
        }
      });
    case "SET_START_TIME":
      return state.map((day) => {
        if (day.id === action.id) {
          /* if (day.totalWork !== null) {
            return { ...day, fieldReadyWork: true };
          } */
          if (
            action.choosenValue === "fieldWork" ||
            action.choosenValue === "readyHours"
          ) {
            return { ...day, workStart: action.value, fieldReadyWork: true };
          } else if (
            day[action.choosenValue] === undefined ||
            day[action.choosenValue] === null
          ) {
            return { ...day, workStart: action.value };
          } else {
            if (action.value === null) {
              return { ...day, workStart: null, fieldReadyWork: false };
            } else {
              return {
                ...day,
                workStart: new Date("undefined"),
                fieldReadyWork: false,
              };
            }
            /*             return { ...day, fieldReadyWork: true }; */
          }
        } else {
          return day;
        }
      });
    case "SET_END_TIME":
      return state.map((day) => {
        const monthTime = monthHours[action.year + action.month];
        console.log(monthTime, monthTime);
        if (day.id === action.id) {
          const workStart =
            day.workStart && day.workStart.getHours() === 0
              ? addDays(day.workStart, 1)
              : day.workStart;
          if (!isValidDate(workStart) && action.value !== null) {
            return {
              ...day,
              workEnd: new Date("undefined"),
              noStartTime: true,
            };
          }
          if (action.value === null) {
            return {
              ...day,
              workEnd: null,
              totalWork: null,
              dayWork: null,
              dayHoliday: null,
              dayExtra: null,
              nightWork: null,
              nightHoliday: null,
              nightExtra: null,
              fieldReadyWork: false,
              noStartTime: false,
              workExists: false,
            };
          }
          const date = new Date(action.value);
          const datePlus = addDays(date, 1);
          const totalWork =
            workStart > action.value
              ? diff_hours(workStart, datePlus)
              : diff_hours(workStart, action.value);

          const overTime = diff_hours(getDate(totalWork), getDate("08:00"));
          if (
            (action.value.getHours() > 22 && action.value.getMinutes() > 0) ||
            action.value.getHours() >= 23 ||
            action.value.getHours() <= 6
          ) {
            let dayTEnd = new Date(day.workStart.getTime());
            dayTEnd.setHours(22);
            dayTEnd.setMinutes(0);
            const isAfterDayWork =
              workStart.getHours() < 6 || workStart.getHours() > 22;
            const totalDayWork = isAfterDayWork
              ? null
              : diff_hours(workStart, dayTEnd);

            const totalNightWork = isAfterDayWork
              ? totalWork
              : diff_hours(getDate(totalWork), getDate(totalDayWork));
            const dayOvertime = diff_hours(
              getDate(totalDayWork),
              getDate("08:00")
            );
            if (action.sundays.includes(Number(day.id))) {
              if (sum > monthTime) {
                console.log(sum);
                extraFlag = monthTime - sum < 0 && true;
                const diff = monthTime - sum;
                const specialDayWorkTime = Math.abs(dayTEnd - workStart) / 36e5;
                const modDayWork = totalNightWork
                  ? `${specialDayWorkTime}:00`
                  : `${specialDayWorkTime - diff}:00`;
                const modNightWork =
                  Number(
                    totalNightWork.substr(0, totalNightWork.indexOf(":"))
                  ) - Math.abs(diff);
                const dayDiff = getHoursFromString(totalWork) - Math.abs(diff);
                /* if (extraFlag === true && diff < 0) {
                extraFlag = false;
              } */
                console.log("holiday + night", modDayWork, specialDayWorkTime, {
                  diff: diff,
                  specialDayWorkTime: specialDayWorkTime,
                  totalNightWork: totalNightWork,
                  isAfterDayWork: isAfterDayWork,
                  extraFlag: extraFlag,
                  moddaywork: modDayWork,
                  modNightWork: modNightWork,
                });
                return {
                  ...day,
                  workEnd: action.value,
                  totalWork: totalWork,
                  dayExtra:
                    extraFlag && !totalNightWork
                      ? specialDayWorkTime < 0
                        ? null
                        : `${correctFormat(Math.abs(diff))}:00`
                      : specialDayWorkTime > 0
                      ? !isAfterDayWork
                        ? dayDiff < 0
                          ? `${correctFormat(specialDayWorkTime)}:00`
                          : dayDiff > 8
                          ? `${correctFormat(specialDayWorkTime - 8)}:00`
                          : `${correctFormat(specialDayWorkTime - dayDiff)}:00`
                        : null
                      : null,
                  dayHoliday: extraFlag
                    ? totalNightWork
                      ? specialDayWorkTime > 0
                        ? !isAfterDayWork
                          ? dayDiff < 0
                            ? null
                            : dayDiff > 8
                            ? "08:00"
                            : `${correctFormat(dayDiff)}:00`
                          : null
                        : null //HERE CHECK IF START TIME IS AFTER DAY WORK TIME
                      : null
                    : null,
                  nightExtra:
                    extraFlag && totalNightWork
                      ? specialDayWorkTime < 0
                        ? totalWork
                        : `${correctFormat(
                            Math.abs(Math.abs(diff) - Math.abs(modNightWork))
                          )}:00`
                      : totalNightWork,

                  nightHoliday: extraFlag
                    ? totalNightWork
                      ? specialDayWorkTime <= 0
                        ? `${correctFormat(modNightWork)}:00`
                        : null
                      : null
                    : null,
                  workExists: true,
                };
                /*  modDayWork < 0 ? null : `${modDayWork}:00`;
                modDayWork < 0
                  ? `${Math.abs(diff - modDayWork)}:00`
                  : `${Math.abs(diff)}:00`; */
              } else {
                return {
                  ...day,
                  workEnd: action.value,
                  totalWork: totalWork,
                  dayHoliday:
                    getDate(totalDayWork).getHours() > 8
                      ? "08:00"
                      : totalDayWork,
                  dayExtra:
                    getDate(totalDayWork).getHours() > 8 ? dayOvertime : null,
                  nightHoliday:
                    getDate(totalDayWork).getHours() > 8
                      ? null
                      : getDate(totalWork).getHours() > 8
                      ? diff_hours(getDate(totalNightWork), getDate(overTime))
                      : totalNightWork,
                  nightExtra:
                    getDate(totalDayWork).getHours() > 8
                      ? totalNightWork
                      : getDate(totalWork).getHours() > 8
                      ? overTime
                      : null,
                  workExists: true,
                };
              }
            }
            if (sum > monthTime) {
              console.log("ordinary", sum);
              extraFlag = monthTime - sum < 0 && true;
              const diff = monthTime - sum;
              const specialDayWorkTime = Math.abs(dayTEnd - workStart) / 36e5;
              const modDayWork = totalNightWork
                ? `${specialDayWorkTime}:00`
                : `${specialDayWorkTime - diff}:00`;
              const modNightWork =
                Number(totalNightWork.substr(0, totalNightWork.indexOf(":"))) -
                Math.abs(diff);
              const dayDiff = getHoursFromString(totalWork) - Math.abs(diff);
              /* if (extraFlag === true && diff < 0) {
                extraFlag = false;
              } */
              console.log("why not negative?", modDayWork, specialDayWorkTime, {
                diff: diff,
                specialDayWorkTime: specialDayWorkTime,
                totalNightWork: totalNightWork,
                isAfterDayWork: isAfterDayWork,
                extraFlag: extraFlag,
                moddaywork: modDayWork,
                dayDiff: dayDiff,
                modNightWork: modNightWork,
              });
              //CHECK VALUES, some need to be absolute, check maximum daywork hours vs dayextra
              //Problem persists when worktime is much bigger then 8 and
              //expl. fond = 184, sum = 172, new value = 13, 8 is maximum for day work, rest goes to overtime even tho it doesnt reach 184
              return {
                ...day,
                workEnd: action.value,
                totalWork: totalWork,
                dayExtra:
                  extraFlag && !totalNightWork
                    ? specialDayWorkTime < 0
                      ? null
                      : `${correctFormat(Math.abs(diff))}:00`
                    : specialDayWorkTime > 0
                    ? !isAfterDayWork
                      ? dayDiff < 0
                        ? `${correctFormat(specialDayWorkTime)}:00`
                        : dayDiff > 8
                        ? `${correctFormat(specialDayWorkTime - 8)}:00`
                        : `${correctFormat(specialDayWorkTime - dayDiff)}:00`
                      : null
                    : null,
                dayWork: extraFlag
                  ? totalNightWork
                    ? specialDayWorkTime > 0
                      ? !isAfterDayWork
                        ? dayDiff < 0
                          ? null
                          : dayDiff > 8
                          ? "08:00"
                          : `${correctFormat(dayDiff)}:00`
                        : null
                      : null //HERE CHECK IF START TIME IS AFTER DAY WORK TIME
                    : null
                  : null,
                nightExtra:
                  extraFlag && totalNightWork
                    ? specialDayWorkTime < 0
                      ? totalWork
                      : `${correctFormat(
                          Math.abs(Math.abs(diff) - Math.abs(modNightWork))
                        )}:00`
                    : totalNightWork,

                nightWork: extraFlag
                  ? totalNightWork
                    ? specialDayWorkTime <= 0
                      ? `${correctFormat(modNightWork)}:00`
                      : null
                    : null
                  : null,
                workExists: true,
              };
            } else {
              return {
                ...day,
                workEnd: action.value,
                totalWork: totalWork,
                dayWork:
                  getDate(totalDayWork).getHours() > 8 ? "08:00" : totalDayWork,
                dayExtra:
                  getDate(totalDayWork).getHours() > 8 ? dayOvertime : null,
                nightWork:
                  getDate(totalDayWork).getHours() > 8
                    ? null
                    : getDate(totalWork).getHours() > 8
                    ? diff_hours(getDate(totalNightWork), getDate(overTime))
                    : totalNightWork,
                nightExtra:
                  getDate(totalDayWork).getHours() > 8
                    ? totalNightWork
                    : getDate(totalWork).getHours() > 8
                    ? overTime
                    : null,
                workExists: true,
              };
            }
          } else {
            if (action.sundays.includes(Number(day.id))) {
              if (sum > monthTime) {
                extraFlag = monthTime - sum < 0 && true;
                const diff = monthTime - sum;
                const modDayWork =
                  Number(totalWork.substr(0, totalWork.indexOf(":"))) -
                  Math.abs(diff);

                /*  if (extraFlag === true && diff < 0) {
                  extraFlag = false;
                } */

                console.log(diff, modDayWork);
                return {
                  ...day,
                  workEnd: action.value,
                  totalWork: totalWork,
                  dayHoliday: extraFlag
                    ? modDayWork < 0
                      ? null
                      : modDayWork > 8
                      ? "08:00"
                      : `${correctFormat(modDayWork)}:00`
                    : null,
                  dayExtra: extraFlag
                    ? modDayWork < 0
                      ? `${correctFormat(Math.abs(diff - modDayWork))}:00`
                      : modDayWork > 8
                      ? `${correctFormat(getHoursFromString(totalWork) - 8)}:00`
                      : `${correctFormat(Math.abs(diff))}:00`
                    : totalWork,
                  workExists: true,
                };
              } else {
                return {
                  ...day,
                  workEnd: action.value,
                  totalWork: totalWork,
                  dayHoliday:
                    getDate(totalWork).getHours() > 8 ? "08:00" : totalWork,
                  dayExtra: getDate(totalWork).getHours() > 8 ? overTime : null,
                  workExists: true,
                };
              }
            } else {
              if (sum > monthTime) {
                extraFlag = monthTime - sum < 0 && true;
                console.log("otherORdinary", sum, extraFlag);
                const diff = monthTime - sum;
                console.log(sum);
                const modDayWork =
                  Number(totalWork.substr(0, totalWork.indexOf(":"))) -
                  Math.abs(diff);
                //REPLACE THIS TO OTHER USE CASES
                /*  if (extraFlag === true && diff < 0) {
                  extraFlag = false;
                } */
                console.log({ modDayWork: modDayWork, diff: diff, sum: sum });
                return {
                  ...day,
                  workEnd: action.value,
                  totalWork: totalWork,
                  dayWork: extraFlag
                    ? modDayWork < 0
                      ? null
                      : modDayWork > 8
                      ? "08:00"
                      : `${correctFormat(modDayWork)}:00`
                    : null,
                  dayExtra: extraFlag
                    ? modDayWork < 0
                      ? `${correctFormat(Math.abs)(diff - modDayWork)}:00`
                      : modDayWork > 8
                      ? `${correctFormat(getHoursFromString(totalWork) - 8)}:00`
                      : `${correctFormat(Math.abs(diff))}:00`
                    : totalWork,
                  workExists: true,
                };
              } else {
                return {
                  ...day,
                  workEnd: action.value,
                  totalWork: totalWork,
                  dayWork:
                    getDate(totalWork).getHours() > 8 ? "08:00" : totalWork,
                  dayExtra: getDate(totalWork).getHours() > 8 ? overTime : null,
                  workExists: true,
                };
              }
            }
          }
        } else {
          return day;
        }
      });
    default:
      return state;
  }
};

const basicInfo1 = {
  company: "",
  fullName: "",
};
const basicInfo2 = {
  month: monthsNum[new Date().getMonth()],
  year: new Date(),
};
const infoReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INFO":
      return {
        ...state,
        [action.stateOption]: action.value,
      };
    default:
      throw new Error();
  }
};

const useStyles = makeStyles({
  root: {
    maxWidth: 324,
    "& label.Mui-focused": {
      color: "#2F0743",
    },
    "& .MuiInput-underline:after": {},
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 4,
        backgroundClip: "padding-box",
        border: "solid 2px #ad5389",
      },
      "&:hover fieldset": {
        borderColor: "#592275",
      },
      "&.Mui-focused fieldset": {
        borderRadius: 4,
        backgroundClip: "padding-box",
        border: "solid 2px #ad5389",
      },
    },
    "& label": {
      color: "#3c1053",
    },
  },
});

const WorkTimesheet = () => {
  const classes = useStyles();

  const [info1, dispatchInfo] = React.useReducer(infoReducer, basicInfo1);
  const [info2, dispatchInfo2] = React.useReducer(infoReducer, basicInfo2);
  const [days, dispatch] = React.useReducer(daysReducer, initialDays);
  const [holidaysTotal, setHolidaysTotal] = React.useState();
  const [totalSum, setTotalSum] = React.useState();
  const [pickedDay, setPickedDay] = React.useState(0);
  const [choosenValue, setChoosenValue] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [getting, setGetting] = React.useState(false);
  const inputLabel = React.useRef(null);
  const inputLabelSecond = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [secondLabelWidth, setSecondLabelWidth] = React.useState(0);
  const [cannotSave, setCannotSave] = React.useState(false);
  const numberOfDays = new Array(31);
  for (let i = 0; i < 31; i++) {
    numberOfDays[i] = i + 1;
  }

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
    setSecondLabelWidth(inputLabelSecond.current.offsetWidth);
  }, []);

  const handleSubmit = (event) => {
    axios
      .post(
        "https://pdf-api.accountant.hr/create-pdf/",
        {
          name: "workTimesheet",
          totalSum,
          info1,
          info2,
          days,
          holidaysTotal,
        },
        {
          responseType: "arraybuffer",
        }
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });

        saveAs(
          blob,
          `${info1.fullName}_${
            months[info2.month]
          }_${info2.year.getFullYear()}_Evidencija.pdf`
        );
      })
      .catch((e) => alert(e));
    event.preventDefault();
  };

  const handleChange = (event, type, day, stateType) => {
    dispatch({
      type: type,
      id: day.id,
      value: event,
      stateOption: stateType,
      sundays: holidaysTotal,
      choosenValue: choosenValue,
      month: info2.month,
      year: info2.year.getFullYear(),
    });
  };
  function formatRightDate(value) {
    for (let prop in value) {
      if (typeof day[prop] === typeof "" && day[prop].includes("-")) {
        return (day[prop] = new Date(day[prop]));
      }
    }
  }
  const handleGetData = () => {
    if (info1.company === "" && info1.fullName === "") {
      setCannotSave(true);
    } else {
      setCannotSave(false);
      axios
        .get(
          `https://pdf-api.accountant.hr/get-form-data/${info1.company}/${info1.fullName}/${info2.month}/${info2.year}`
        )
        .then((res) => {
          setGetting(true);
          const actualData = JSON.parse(res.data[0][0].dani);
          const addedDates = actualData.map((day) => {
            Object.keys(day).forEach((prop) => {
              if (typeof day[prop] === typeof "" && day[prop].includes("-")) {
                day[prop] = new Date(day[prop]);
              }
            });
            return day;
          });

          dispatch({ type: "TEST", state: addedDates });
          setTimeout(() => {
            setGetting(false);
          }, 1000);
        })
        .catch((err) => {
          setCannotSave(true);
        });
    }
  };

  const handlePostData = () => {
    if (info1.company === "" && info1.fullName === "") {
      setCannotSave(true);
    } else {
      setCannotSave(false);
      setSaving(true);
      axios
        .post("https://pdf-api.accountant.hr/post-form-data", {
          info1,
          info2,
          days,
        })
        .then((res) => {
          setTimeout(() => {
            setSaving(false);
          }, 1000);
        })
        .catch((err) => {
          setCannotSave(true);
        });
    }
  };
  const setCalendarDate = (event) => {
    setPickedDay(event.getDate() - 1);
    dispatchInfo2({
      type: "UPDATE_INFO",
      value: monthsNum[event.getMonth()],
      stateOption: "month",
    });
    dispatchInfo2({
      type: "UPDATE_INFO",
      value: event,
      stateOption: "year",
    });
  };
  const filteredOptions = valuesToChoose.filter((item) => {
    return item === days[pickedDay].chosenValue;
  });
  const filteredTwoOptions = valuesToChoose.filter((item) => {
    return item === "readyHours" || item === "fieldWork";
  });
  /*   const usingOptions = days[pickedDay].alreadyAdded
    ? filteredOptions
    : days[pickedDay].fieldReadyWork
    ? filteredTwoOptions
    : valuesToChoose; */
  const usingOptions = !days[pickedDay].alreadyAdded
    ? days[pickedDay].fieldReadyWork || days[pickedDay].workExists
      ? filteredTwoOptions
      : valuesToChoose
    : filteredOptions;
  const filteredDay = pickedDay <= 29 ? pickedDay + 1 : pickedDay;

  return (
    <>
      <Typography
        style={{
          textAlign: "center",
          fontSize: 24,
          marginBottom: 20,
          color: "#616161",
        }}
      >
        Evidencija o radnom vremenu radnika u mjesecu
      </Typography>
      <main>
        <section className={style.inputs}>
          <div className={style.calendar}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hrLocale}>
              <DatePicker
                variant="static"
                openTo="date"
                value={
                  new Date(
                    `${pickedDay + 1} ${
                      info2.month
                    } ${info2.year.getFullYear()}`
                  )
                }
                onChange={(event) => {
                  setCalendarDate(event);
                  setChoosenValue(days[event.getDate() - 1].chosenValue);
                }}
                classes={{
                  root: classes.root,
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div
            style={{
              display: "flex",
              maxWidth: 400,
              width: "100%",
              flexDirection: "column",
              /*  justifyContent: "center", */
              flexWrap: "wrap",
              padding: 25,
              boxShadow: "7px 4px 30px -10px rgba(0,0,0,0.75)",
              borderRadius: 5,
              background: "linear-gradient(to right, #ad5389, #3c1053)",
            }}
          >
            <BasicInfo info={info1} dispatchInfo={dispatchInfo} />
            <BasicInfo2 info={info2} dispatchInfo={dispatchInfo2} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 400,
              width: "100%",
              flexWrap: "wrap",
              padding: 25,
              boxShadow: "7px 4px 30px -10px rgba(0,0,0,0.75)",
              borderRadius: 5,
            }}
          >
            <FormControl
              variant="outlined"
              classes={{
                root: classes.root,
              }}
            >
              <InputLabel ref={inputLabel} htmlFor="brojDana">
                Dan u mjesecu
              </InputLabel>
              <Select
                native
                labelWidth={labelWidth}
                value={pickedDay}
                onChange={() => {
                  setPickedDay(Number(event.target.value));
                  setChoosenValue(days[event.target.value].chosenValue);
                }}
                inputProps={{
                  name: "pickedDay",
                  id: "brojDana",
                }}
              >
                {numberOfDays.map((item) => {
                  return (
                    <option key={item} value={item - 1}>
                      {item}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <div style={{ display: "flex" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  classes={{
                    root: classes.root,
                  }}
                  inputVariant="outlined"
                  variant="inline"
                  className={style.timePicker}
                  ampm={false}
                  invalidDateMessage="Neispravan format unosa"
                  label="Početak rada"
                  value={days[pickedDay].workStart}
                  onChange={(event) =>
                    handleChange(event, "SET_START_TIME", days[pickedDay])
                  }
                />
              </MuiPickersUtilsProvider>
              <Icon
                className={style.icon}
                onClick={() => {
                  handleChange(
                    null,
                    "SET_START_TIME",
                    days[pickedDay],
                    "workStart"
                  );
                }}
              >
                clear
              </Icon>
            </div>
            <div style={{ display: "flex" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  classes={{
                    root: classes.root,
                  }}
                  inputVariant="outlined"
                  variant="inline"
                  label="Završetak rada"
                  className={style.timePicker}
                  ampm={false}
                  value={days[pickedDay].workEnd}
                  invalidDateMessage={
                    days[pickedDay].noStartTime
                      ? "Prvo unesite vrijeme početka rada"
                      : "Neispravan format unosa"
                  }
                  onChange={(event) =>
                    handleChange(event, "SET_END_TIME", days[pickedDay])
                  }
                />
              </MuiPickersUtilsProvider>
              <Icon
                className={style.icon}
                onClick={(event) => {
                  handleChange(
                    null,
                    "SET_END_TIME",
                    days[pickedDay],
                    "workEnd"
                  );
                }}
              >
                clear
              </Icon>
            </div>
            <div style={{ display: "flex" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  classes={{
                    root: classes.root,
                  }}
                  inputVariant="outlined"
                  variant="inline"
                  className={style.timePicker}
                  label="Sati zastoja bez krivnje radnika - od"
                  ampm={false}
                  invalidDateMessage={
                    days[pickedDay].noHaltStart
                      ? "Prvo unesite radno vrijeme"
                      : "Neispravan format unosa"
                  }
                  value={days[pickedDay].haltStart}
                  onChange={(event) =>
                    handleChange(
                      event,
                      "SET_STATE",
                      days[pickedDay],
                      "haltStart"
                    )
                  }
                />
              </MuiPickersUtilsProvider>
              <Icon
                className={style.icon}
                onClick={(event) => {
                  handleChange(null, "SET_STATE", days[pickedDay], "haltStart");
                }}
              >
                clear
              </Icon>
            </div>
            <div style={{ display: "flex" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  classes={{
                    root: classes.root,
                  }}
                  inputVariant="outlined"
                  variant="inline"
                  className={style.timePicker}
                  invalidDateMessage={
                    days[pickedDay].noHaltTime
                      ? "Prvo unesite početno vrijeme zastoja"
                      : "Neispravan format unosa"
                  }
                  label="Sati zastoja bez krivnje radnika - do"
                  ampm={false}
                  value={days[pickedDay].haltEnd}
                  onChange={(event) =>
                    handleChange(event, "SET_STATE", days[pickedDay], "haltEnd")
                  }
                />
              </MuiPickersUtilsProvider>
              <Icon
                className={style.icon}
                onClick={(event) => {
                  handleChange(null, "SET_STATE", days[pickedDay], "haltEnd");
                }}
              >
                clear
              </Icon>
            </div>
            <FormControl
              variant="outlined"
              style={{ marginTop: 10 }}
              classes={{
                root: classes.root,
              }}
            >
              <InputLabel ref={inputLabelSecond} htmlFor="izabranaVrijednost">
                Ostale...
              </InputLabel>
              <Select
                variant="outlined"
                native
                value={choosenValue}
                labelWidth={secondLabelWidth}
                onChange={() => {
                  setChoosenValue(event.target.value);
                  dispatch({
                    type: "SET_CHOSEN",
                    id: days[pickedDay].id,
                    chosen: event.target.value,
                  });
                }}
                inputProps={{
                  name: "chosenValue",
                  id: "izabranaVrijednost",
                }}
              >
                {!days[pickedDay].alreadyAdded ? <option value="" /> : null}
                {usingOptions.map((item) => {
                  return (
                    <option key={item} value={item}>
                      {choosenValuesEnum[item]}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <div style={{ display: "flex" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  classes={{
                    root: classes.root,
                  }}
                  inputVariant="outlined"
                  variant="inline"
                  className={style.timePicker}
                  label={
                    choosenValuesEnum[choosenValue] === undefined
                      ? "Broj sati"
                      : choosenValuesEnum[choosenValue] + " - broj sati"
                  }
                  ampm={false}
                  invalidDateMessage={
                    days[pickedDay].fieldWorkFlag
                      ? "Unijeli ste vrijeme veće od ukupnog radnog vremena"
                      : "Neispravan format unosa"
                  }
                  value={days[pickedDay][choosenValue]}
                  onChange={(event) =>
                    handleChange(
                      event,
                      "SET_STATE",
                      days[pickedDay],
                      choosenValue
                    )
                  }
                />
              </MuiPickersUtilsProvider>
              <Icon
                className={style.icon}
                onClick={(event) => {
                  handleChange(
                    null,
                    "SET_STATE",
                    days[pickedDay],
                    choosenValue
                  );
                }}
              >
                clear
              </Icon>
            </div>
            <div style={{ display: "flex", marginTop: 20 }}>
              <Button
                className={style.saveBtn}
                variant="contained"
                style={{
                  margin: "8px auto",
                  width: 100,
                  color: "white",
                }}
                onClick={() =>
                  days[pickedDay].haltStart !== null
                    ? handleChange(
                        days[pickedDay].haltEnd,
                        "SET_STATE",
                        days[pickedDay],
                        "haltEnd"
                      )
                    : handleChange(
                        days[pickedDay].workEnd,
                        "SET_END_TIME",
                        days[pickedDay]
                      )
                }
              >
                Potvrdi
              </Button>
              <Button
                className={style.saveBtn}
                variant="contained"
                style={{
                  margin: "8px auto",
                  width: 140,
                  color: "white",
                }}
                onClick={() => {
                  setPickedDay(filteredDay);
                  setChoosenValue(days[filteredDay].chosenValue);
                }}
              >
                Sljedeći dan
              </Button>
              <Button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "COPY_LAST",
                    id: days[pickedDay].id,
                  });
                  days[pickedDay].haltStart !== null
                    ? handleChange(
                        days[pickedDay].haltEnd,
                        "SET_STATE",
                        days[pickedDay],
                        "haltEnd"
                      )
                    : handleChange(
                        days[pickedDay].workEnd,
                        "SET_END_TIME",
                        days[pickedDay]
                      );
                }}
              >
                copy
              </Button>
            </div>
          </div>
        </section>
        <div className={style.buttonGroup}>
          <Button
            disabled={saving ? true : false}
            variant="contained"
            onClick={() => handlePostData()}
          >
            Spremi podatke
          </Button>
          <Button variant="contained" onClick={() => handleGetData()}>
            Dohvati podatke
          </Button>
          {getting ? (
            <p
              style={{
                position: "absolute",
                marginTop: "60px",
                alignSelf: "center",
                color: "#4CAF50",
                fontFamily: "Roboto",
              }}
            >
              Podaci dohvaćeni
            </p>
          ) : null}
          {saving ? (
            <p
              style={{
                position: "absolute",
                marginTop: "60px",
                alignSelf: "center",
                color: "#4CAF50",
                fontFamily: "Roboto",
              }}
            >
              Podaci spremljeni
            </p>
          ) : null}
          {cannotSave ? (
            <p
              style={{
                position: "absolute",
                marginTop: "60px",
                alignSelf: "center",
                color: "red",
                fontFamily: "Roboto",
              }}
            >
              Niste unijeli naziv tvrtke, prezime i ime radnika
            </p>
          ) : null}
        </div>
        <TimesheetTable
          info={info2}
          days={days}
          dispatch={dispatch}
          addTimes={addTimes}
          setHolidaysTotal={setHolidaysTotal}
          setTotalSum={setTotalSum}
        />
        <Button
          className={style.downloadBtn}
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Preuzmi PDF
        </Button>
      </main>
    </>
  );
};

export default WorkTimesheet;
