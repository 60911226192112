.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 800px;
  width: 100%;

  button {
    display: flex;
    justify-content: center;
    margin: 50px auto;
  }
}
.formItem {
  display: flex;
  margin: 0 10px 30px 10px;
  max-width: 800px;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  label {
    display: flex;
    flex: 1;
    max-width: 300px;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 18px;
    text-align: justify;
    margin-right: 10px;
  }
}
.error {
  display: flex;
  flex: 0 0 50%;
  justify-content: center;
  margin: auto;
  text-align: center;
  color: #e53935;
}
