import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { Formik, FastField, Form, ErrorMessage } from "formik";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import hrLocale from "date-fns/locale/en-US";
import ErrorFocus from "../ErrorFocus/ErrorFocus";
import * as Yup from "yup";
import style from "./cinjeniceOporez.module.scss";
import { b64toBlob } from "../../functions";

const validation = Yup.object().shape({
  oib: Yup.string()
    .matches(/^[0-9]+$/, "Dozvoljeni su sami brojevi")
    .min(11, "OIB mora sadržavati 11 znamenaka")
    .max(11, "OIB mora sadržavati 11 znamenaka"),
  oib2: Yup.string()
    .matches(/^[0-9]+$/, "Dozvoljeni su sami brojevi")
    .min(11, "OIB mora sadržavati 11 znamenaka")
    .max(11, "OIB mora sadržavati 11 znamenaka"),
  oib4: Yup.string()
    .matches(/^[0-9]+$/, "Dozvoljeni su sami brojevi")
    .min(11, "OIB mora sadržavati 11 znamenaka")
    .max(11, "OIB mora sadržavati 11 znamenaka"),
  oib5: Yup.string()
    .matches(/^[0-9]+$/, "Dozvoljeni su sami brojevi")
    .min(11, "OIB mora sadržavati 11 znamenaka")
    .max(11, "OIB mora sadržavati 11 znamenaka"),
});

const cinjeniceOporez = () => {
  const input = [
    { ime: "Ime: " },
    { prezime: "Prezime: " },
    { spol: "Spol: " },
    { oib: "OIB: " },
    { adresa: "Prebivalište (država, mjesto, ulica, kućni broj): " },
    { boraviste: "Uobičajeno boravište (država, mjesto, ulica, kućni broj): " },
  ];
  const input2 = [
    { naziv: "Naziv: " },
    { sjediste: "Sjedište (država, mjesto, ulica i kućni broj): " },
    { pravnaOsoba: "Pravna osnova na kojoj se temelji upis sjedišta: " },
    { oib2: "OIB: " },
    { pdvBroj: "PDV identifikacijski broj: " },
    { mbs: "MBS/Broj upisa u nadležni registar: " },
    { racunIzvanHrv: "Broj poslovnog računa izvan Republike Hrvatske: " },
  ];
  const input3 = [
    { datumOsnutka: "Datum osnutka radionice ili stalne poslovne jedinice: " },
    {
      datumPremjestaja:
        "Datum premještaja radionice ili stalne poslovne jedinice: ",
    },
    {
      promjenaDjelatnosti:
        "Promjena djelatnosti koja može činiti stalnu poslovnu jedinicu: ",
    },
    {
      dosadasnjaAdresa:
        "Dosadašnja adresa radionice ili stalne poslovne jedinice (država, mjesto, ulica, kućni broj): ",
    },
    {
      novaAdresa:
        "Nova adresa radionice ili stalne poslovne jedinice (država, mjesto, ulica, kućni broj): ",
    },
    {
      datumPocetkaObavljanja:
        "Datum početka obavljanja gospodarske djelatnosti: ",
    },
    {
      datumPrestankaObavljanja:
        "Datum prestanka obavljanja gospodarske djelatnosti: ",
    },
  ];
  const input4 = [
    { nazivTrg: "Naziv trgovačkog društva čiji su udjeli stečeni: " },
    { oib4: "OIB (porezni broj) trgovačkog društva čiji su udjeli stečeni: " },
    { mbs4: "MBS trgovačkog društva čiji su udjeli stečeni: " },
    {
      sjediste4:
        "Sjedište trgovačkog društva čiji su udjeli stečeni (država, mjesto, ulica i broj): ",
    },
    { vrstaUdjela: "Vrsta udjela/rod: " },
    { velUdjela: "Veličina udjela i nominalna vrijednost: " },
    { nacinStjecanja: "Način stjecanja: " },
    { datumPrijenosa: "Datum prijenosa udjela: " },
    {
      datumUpisa:
        "Datum upisa stjecanja u nadležni registar ili na račun koji se vodi kod nadležnog tijela: ",
    },
    { nazivTijela: "Naziv tijela koje vodi registar: " },
  ];
  const input5 = [
    { naziv5: "Ime i prezime/Naziv: " },
    {
      prebivaliste5:
        "Prebivalište ili uobičajeno boravište/sjedište (država, mjesto, ulica i broj): ",
    },
    { oib5: "OIB (porezni broj): " },
    {
      mbs5: "MBS: ",
    },
    { vrstaUdjela5: "Vrsta udjela/rod: " },
    { velUdjela5: "Veličina udjela i nominalna vrijednost: " },
    { nacinStjecanja5: "Način stjecanja: " },
    { datumPrijenosa5: "Datum prijenosa udjela: " },
    {
      datumUpisa5:
        "Datum upisa stjecanja u nadležni registar ili na račun koji se vodi kod nadležnog tijela: ",
    },
    { nazivTijela5: "Naziv tijela koje vodi registar: " },
  ];
  const input6 = [
    { nazivDrustva: "Naziv kontroliranog inozemnog društva:: " },
    {
      porezniBroj:
        "Porezni broj/osobni identifikacijski broj ili druga identifikacijska oznaka: ",
    },
    {
      sjediste6:
        "Sjedište (država – porezna jurisdikcija) – mjesto, ulica i broj: ",
    },
    { ukupanUdio: "Ukupan izravni i neizravni udio: " },
    { datumStjecanja: "Datum stjecanja: " },
  ];
  const input7 = [
    {
      imePrezimeOIB:
        "Osoba ovlaštena za vođenje poslovnih knjiga - ime i prezime/naziv; OIB; broj telefona; e-mail adresa: ",
    },
    {
      ovlastenaOsoba:
        "Osoba ovlaštena za zaprimanje pismena ime i prezime/naziv; OIB; broj telefona; e-mail adresa: ",
    },
    {
      opunomocenik:
        "Opunomoćenik - ime i prezime/naziv; OIB; broj telefona; e-mail adresa: ",
    },
  ];
  function expand(obj) {
    var keys = Object.keys(obj);
    for (var i = 0; i < keys.length; ++i) {
      var key = keys[i],
        subkeys = key.split(/,\s?/),
        target = obj[key];
      delete obj[key];
      subkeys.forEach(function (key) {
        obj[key] = target;
      });
    }
    return obj;
  }
  const inputs = (name, id, value, onChange, setFieldValue) =>
    expand({
      "ime, prezime, spol, naziv, pravnaOsoba, racunIzvanHrv, nazivTrg, vrstaUdjela, velUdjela,nacinStjecanja, nazivTijela, naziv5, vrstaUdjela5, velUdjela5, nacinStjecanja5, nazivTijela5, nazivDrustva, ukupanUdio, ":
        (
          <TextField
            name={name}
            id={id}
            value={value}
            onChange={onChange}
            fullWidth={true}
            style={{ maxWidth: 410, marginTop: 0 }}
            margin="normal"
            variant="outlined"
          />
        ),
      "oib, oib2, pdvBroj, mbs, oib4, mbs4, oib5, mbs5, porezniBroj": (
        <TextField
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          fullWidth={true}
          style={{ maxWidth: 410, marginTop: 0 }}
          margin="normal"
          variant="outlined"
        />
      ),

      "adresa, boraviste, sjediste, promjenaDjelatnosti, dosadasnjaAdresa, novaAdresa, sjediste4, prebivaliste5, sjediste6, imePrezimeOIB, ovlastenaOsoba, opunomocenik":
        (
          <TextField
            name={name}
            id={id}
            value={value}
            onChange={onChange}
            multiline
            rows="4"
            fullWidth={true}
            style={{ maxWidth: 410, marginTop: 0 }}
            margin="normal"
            variant="outlined"
          />
        ),
      "datumOsnutka, datumPremjestaja, datumPocetkaObavljanja, datumPrijenosa, datumUpisa,datumPrestankaObavljanja, datumPrijenosa5, datumUpisa5, datumStjecanja,":
        (
          <>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hrLocale}>
              <DatePicker
                format="dd.MM.yyyy"
                inputVariant="outlined"
                value={value}
                onChange={(value) => setFieldValue(name, value)}
              />
            </MuiPickersUtilsProvider>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row style={{ margin: "auto" }}>
                <FormControlLabel
                  value="top"
                  control={
                    <Switch
                      color="primary"
                      checked={value === null ? false : true}
                      onChange={() => {
                        const val = value === null ? new Date() : null;
                        console.log(val);
                        setFieldValue(name, val);
                      }}
                    />
                  }
                  label={value === null ? "Disabled" : "Enabled"}
                  labelPlacement="top"
                />
              </FormGroup>
            </FormControl>
          </>
        ),
    });
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Typography
        variant="h5"
        style={{ textAlign: "center", marginBottom: 40 }}
      >
        Prijava činjenica bitnih za oporezivanje
      </Typography>
      <main className={style.wrapper}>
        <Formik
          initialValues={{
            name: "cinjeniceOporez",
            ime: "",
            prezime: "",
            spol: "",
            oib: "",
            adresa: "",
            boraviste: "",
            naziv: "",
            sjediste: "",
            pravnaOsoba: "",
            oib2: "",
            pdvBroj: "",
            mbs: "",
            racunIzvanHrv: "",
            datumOsnutka: new Date(),
            datumPremjestaja: new Date(),
            promjenaDjelatnosti: "",
            dosadasnjaAdresa: "",
            novaAdresa: "",
            datumPocetkaObavljanja: new Date(),
            datumPrestankaObavljanja: new Date(),
            nazivTrg: "",
            oib4: "",
            mbs4: "",
            sjediste4: "",
            vrstaUdjela: "",
            velUdjela: "",
            nacinStjecanja: "",
            datumPrijenosa: new Date(),
            datumUpisa: new Date(),
            nazivTijela: "",
            naziv5: "",
            prebivaliste5: "",
            oib5: "",
            mbs5: "",
            vrstaUdjela5: "",
            velUdjela5: "",
            nacinStjecanja5: "",
            datumPrijenosa5: new Date(),
            datumUpisa5: new Date(),
            nazivTijela5: "",
            nazivDrustva: "",
            porezniBroj: "",
            sjediste6: "",
            ukupanUdio: "",
            datumStjecanja: new Date(),
            imePrezimeOIB: "",
            ovlastenaOsoba: "",
            opunomocenik: "",
            cinjenice: "",
            napomene: "",
            popis: "",
            datum: "",
          }}
          validationSchema={validation}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            console.log(values);
            const vals = {
              ...values,
              datumOsnutka:
                values.datumOsnutka === null ? "" : values.datumOsnutka,
              datumPremjestaja:
                values.datumPremjestaja === null ? "" : values.datumPremjestaja,
              datumPocetkaObavljanja:
                values.datumPocetkaObavljanja === null
                  ? ""
                  : values.datumPocetkaObavljanja,
              datumPrestankaObavljanja:
                values.datumPrestankaObavljanja === null
                  ? ""
                  : values.datumPrestankaObavljanja,
              datumPrijenosa:
                values.datumPrijenosa === null ? "" : values.datumPrijenosa,
              datumUpisa: values.datumUpisa === null ? "" : values.datumUpisa,
              datumPrijenosa5:
                values.datumPrijenosa5 === null ? "" : values.datumPrijenosa5,
              datumUpisa5:
                values.datumUpisa5 === null ? "" : values.datumUpisa5,
              datumStjecanja:
                values.datumStjecanja === null ? "" : values.datumStjecanja,
            };
            axios
              .post("https://pdf-api.accountant.hr/create-pdf/", vals)
              .then((res) => {
                const blob = b64toBlob(res.data, "application/pdf");
                saveAs(blob, "prijava-cinjenica.pdf");
              })
              .then(() => {
                actions.setSubmitting(false);
              })

              .catch((e) => alert(e));
          }}
          render={({ status, isSubmitting }) => (
            <Form className={style.form}>
              <Typography
                variant="h6"
                style={{ margin: "0 0 20px 0", textAlign: "center" }}
              >
                1. Opći podaci o poreznom obvezniku (fizičkoj osobi)
              </Typography>
              {input.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          <label htmlFor={key}>
                            <Typography>{item[key]}</Typography>
                          </label>
                          <FastField
                            rows="3"
                            cols="50"
                            name={key}
                            render={({ field, form }) => {
                              return inputs(
                                field.name,
                                field.id,
                                field.value,
                                field.onChange
                              )[field.name];
                            }}
                          />

                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography
                variant="h6"
                style={{ margin: "40px 0 40px 0", textAlign: "center" }}
              >
                2. Opći podaci o poreznom obvezniku (pravnoj osobi)
              </Typography>
              {input2.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          <label htmlFor={key}>
                            <Typography>{item[key]}</Typography>
                          </label>
                          <FastField
                            rows="3"
                            cols="50"
                            name={key}
                            render={({ field, form }) => {
                              return inputs(
                                field.name,
                                field.id,
                                field.value,
                                field.onChange
                              )[field.name];
                            }}
                          />

                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography
                variant="h6"
                style={{ margin: "40px 0 40px 0", textAlign: "center" }}
              >
                3. Podaci o premještaju i prestanku radionice ili stalne
                poslovne jedinice, početku, prestanku i promjeni obavljanja
                gospodarske djelatnosti
              </Typography>
              {input3.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          <label htmlFor={key}>
                            <Typography>{item[key]}</Typography>
                          </label>
                          <FastField
                            rows="3"
                            cols="50"
                            name={key}
                            render={({ field, form }) => {
                              return inputs(
                                field.name,
                                field.id,
                                field.value,
                                field.onChange,
                                form.setFieldValue
                              )[field.name];
                            }}
                          />

                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography
                variant="h6"
                style={{ margin: "40px 0 40px 0", textAlign: "center" }}
              >
                4. Podaci o izravnom ili neizravnom stjecanju više od 50%
                udjela/većinskih članskih prava u tuzemnom ili inozemnom
                trgovačkom društvu
              </Typography>
              {input4.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          <label htmlFor={key}>
                            <Typography>{item[key]}</Typography>
                          </label>
                          <FastField
                            rows="3"
                            cols="50"
                            name={key}
                            render={({ field, form }) => {
                              return inputs(
                                field.name,
                                field.id,
                                field.value,
                                field.onChange,
                                form.setFieldValue
                              )[field.name];
                            }}
                          />

                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography
                variant="h6"
                style={{ margin: "40px 0 40px 0", textAlign: "center" }}
              >
                5. Podaci o osobi koja ima izravno ili neizravno 50% ili više
                udjela/većinska članska prava nad trgovačkim društvom –
                podnositeljem Prijave
              </Typography>
              {input5.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          <label htmlFor={key}>
                            <Typography>{item[key]}</Typography>
                          </label>
                          <FastField
                            rows="3"
                            cols="50"
                            name={key}
                            render={({ field, form }) => {
                              return inputs(
                                field.name,
                                field.id,
                                field.value,
                                field.onChange,
                                form.setFieldValue
                              )[field.name];
                            }}
                          />

                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography
                variant="h6"
                style={{ margin: "40px 0 40px 0", textAlign: "center" }}
              >
                6. Podaci o kontroliranom inozemnom društvu iz članaka 30.b i
                30.c Zakona o porezu na dobit
              </Typography>
              {input6.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          <label htmlFor={key}>
                            <Typography>{item[key]}</Typography>
                          </label>
                          <FastField
                            rows="3"
                            cols="50"
                            name={key}
                            render={({ field, form }) => {
                              return inputs(
                                field.name,
                                field.id,
                                field.value,
                                field.onChange,
                                form.setFieldValue
                              )[field.name];
                            }}
                          />
                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography
                variant="h6"
                style={{ margin: "40px 0 40px 0", textAlign: "center" }}
              >
                7. Podaci o opunomoćenicima
              </Typography>
              {input7.map((item, index) => {
                return (
                  <div key={index} className={style.formItem}>
                    {Object.keys(item).map((key) => {
                      return (
                        <React.Fragment key={index + key}>
                          <label htmlFor={key}>
                            <Typography>{item[key]}</Typography>
                          </label>
                          <FastField
                            rows="3"
                            cols="50"
                            name={key}
                            render={({ field, form }) => {
                              return inputs(
                                field.name,
                                field.id,
                                field.value,
                                field.onChange
                              )[field.name];
                            }}
                          />

                          <ErrorMessage
                            className={style.error}
                            name={key}
                            component="div"
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
              <Typography
                variant="h6"
                style={{ margin: "40px 0 40px 0", textAlign: "center" }}
              >
                8. Druge činjenice koje mogu biti od utjecaja za obračun,
                utvrđivanje ili naplatu poreza
              </Typography>
              <div
                className={style.formItem}
                style={{ justifyContent: "center" }}
              >
                <FastField
                  rows="5"
                  cols="50"
                  name="cinjenice"
                  style={{ margin: "auto" }}
                  render={({ field, form }) => (
                    <TextField
                      name={field.name}
                      id={field.id}
                      value={field.value}
                      onChange={field.onChange}
                      multiline
                      rows="4"
                      fullWidth={true}
                      style={{ maxWidth: 410, marginTop: 0 }}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
                <ErrorMessage
                  className={style.error}
                  name="cinjenice"
                  component="div"
                />
              </div>
              <Typography
                variant="h6"
                style={{ margin: "40px 0 40px 0", textAlign: "center" }}
              >
                9. Napomene podnositelja Prijave
              </Typography>
              <div
                className={style.formItem}
                style={{ justifyContent: "center" }}
              >
                <FastField
                  rows="5"
                  cols="50"
                  name="napomene"
                  style={{ margin: "auto" }}
                  render={({ field, form }) => (
                    <TextField
                      name={field.name}
                      id={field.id}
                      value={field.value}
                      onChange={field.onChange}
                      multiline
                      rows="4"
                      fullWidth={true}
                      style={{ maxWidth: 410, marginTop: 0 }}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
                <ErrorMessage
                  className={style.error}
                  name="napomene"
                  component="div"
                />
              </div>
              <Typography
                variant="h6"
                style={{ margin: "40px 0 40px 0", textAlign: "center" }}
              >
                10. Popis priloženih isprava
              </Typography>
              <div
                className={style.formItem}
                style={{ justifyContent: "center" }}
              >
                <FastField
                  rows="5"
                  cols="50"
                  name="popis"
                  style={{ margin: "auto" }}
                  render={({ field, form }) => (
                    <TextField
                      name={field.name}
                      id={field.id}
                      value={field.value}
                      onChange={field.onChange}
                      multiline
                      rows="4"
                      fullWidth={true}
                      style={{ maxWidth: 410, marginTop: 0 }}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />

                <ErrorMessage
                  className={style.error}
                  name="popis"
                  component="div"
                />
              </div>
              <Typography
                variant="h6"
                style={{ margin: "40px 0 40px 0", textAlign: "center" }}
              >
                Mjesto i datum podnošenja
              </Typography>
              <div
                className={style.formItem}
                style={{ justifyContent: "center" }}
              >
                <FastField
                  rows="5"
                  cols="50"
                  name="datum"
                  style={{ margin: "auto" }}
                  render={({ field, form }) => (
                    <TextField
                      name={field.name}
                      id={field.id}
                      value={field.value}
                      onChange={field.onChange}
                      multiline
                      rows="4"
                      fullWidth={true}
                      style={{ maxWidth: 410, marginTop: 0 }}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />

                <ErrorMessage
                  className={style.error}
                  name="datum"
                  component="div"
                />
              </div>

              {status && status.msg && <div>{status.msg}</div>}

              {isSubmitting ? (
                <CircularProgress style={{ marginTop: 20, marginBottom: 40 }} />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Spremi PDF
                </Button>
              )}
              <ErrorFocus />
            </Form>
          )}
        />
      </main>
    </>
  );
};

export default cinjeniceOporez;
