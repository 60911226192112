import React from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
/* import TableHead from "@material-ui/core/TableHead"; */
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
/* import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; */
import style from "./TimesheetTable.module.scss";
import debounce from "lodash/debounce";
import { sums } from "../../constants/index";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    maxWidth: "1857px",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(1),
    display: "flex",
    overflow: "hidden",
  },
  table: {
    minWidth: 1200,
    border: "1px solid black",
  },
  tableCell: {
    padding: "6px 1px 6px 1px",
    textAlign: "center",
    /* width: 20, */
    border: "1px solid black",
    borderCollapse: "inherit",
    width: 50,
  },
  smallRow: {
    fontSize: 13,
    height: 33,
  },
  tableRotate: {
    borderCollapse: "collapse",
  },
  td: {
    border: "1px solid black",
  },
  extraPadding: {
    padding: "4px !important",
    border: "1px solid black",
    borderLeft: "2px solid black",
    textAlign: "center",
    width: 59,
  },
}));
const TimesheetTable = ({
  info,
  days,
  dispatch,
  addTimes,
  setHolidaysTotal,
  setTotalSum,
}) => {
  function getSundays(date) {
    const getTotal = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    let sun = [];
    for (let i = 1; i <= getTotal; i++) {
      let newDate = new Date(date.getFullYear(), date.getMonth(), i);
      if (newDate.getDay() == 0) {
        sun.push(i);
      }
    }
    return sun;
  }
  let sundays = getSundays(
    new Date(`01 ${info.month} ${info.year.getFullYear()}`)
  );
  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }
  const getTime = (date) => {
    if (date === null || date === "NaN:NaN" || !isValidDate(date)) {
      return null;
    }
    if (typeof date === "string") {
      date = new Date(date);
    }
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (date.getHours() < 10) {
      hours = `0${date.getHours()}`;
    }
    if (date.getMinutes() < 10) {
      minutes = `0${date.getMinutes()}`;
    }
    return `${hours}:${minutes}`;
  };

  const sumItems = (property) => {
    return days.map((item) => {
      if (item[property] !== null) {
        if (
          property === "totalWork" ||
          property === "dayWork" ||
          property === "dayExtra" ||
          property === "dayHoliday" ||
          property === "nightWork" ||
          property === "nightExtra" ||
          property === "nightHoliday"
        ) {
          if (
            item[property] === "NaN:NaN" ||
            item[property] === null ||
            item[property] === undefined
          ) {
            return "00:00";
          }
          return item[property];
        } else {
          return getTime(item[property]);
        }
      } else {
        return "00:00";
      }
    });
  };

  const [holidays, setHolidays] = React.useState([]);
  React.useEffect(() => {
    getHolidays(info.year);
  }, [info.year]);
  function getHolidays(date) {
    const year = date.getFullYear();
    axios
      .get(
        `https://www.googleapis.com/calendar/v3/calendars/hr.croatian%23holiday%40group.v.calendar.google.com/events?timeMax=${year}-12-31T10%3A00%3A00-07%3A00&timeMin=${year}-01-01T10%3A00%3A00-07%3A00&key=AIzaSyB0ABhruzsyBP5XS055z6l5JhFZcNHbhj4`
      )
      .then((res) => {
        setHolidays(res.data.items);
      });
  }
  const months = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };
  holidays.map((item) => {
    if (
      item.summary !== "Pravoslavni Božić" &&
      item.summary !== "Ramadan Bajram" &&
      item.summary !== "Kurban Bajram" &&
      item.summary !== "Roš hašana" &&
      item.summary !== "Yom Kipura"
    ) {
      if (new Date(item.start.date).getMonth() === months[info.month]) {
        sundays.push(new Date(item.start.date).getDate());
      }
    }
    return null;
  });
  setHolidaysTotal(sundays);
  /*   const handleChange = debounce((event, type, day, stateType) => {
    dispatch({
      type: type,
      id: day.id,
      value: event,
      stateOption: stateType,
      sundays: sundays
    });
  }, 500); */

  const classes = useStyles();
  function sumAllItems(itemName) {
    return addTimes(sumItems(itemName)) === "00:00"
      ? null
      : addTimes(sumItems(itemName));
  }

  const allItemsSum = sums.map((item) => {
    return sumAllItems(item);
  });
  setTotalSum(addTimes(allItemsSum));

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 250, minWidth: 250 }}
          >
            <TableBody>
              <TableRow style={{ height: 66 }}>
                <TableCell border={1} component="th" scope="row" align="center">
                  OPIS
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Početak rada
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Završetak rada
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ height: 66 }}
                >
                  Sati zastoja bez krivnje radnika
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Ukupno dnevno radno vrijeme
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table className={`${classes.table} ${style.tableAuto}`} size="small">
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  colSpan="31"
                >
                  DANI U MJESECU
                </TableCell>
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="th"
                      className={classes.tableCell}
                      scope="row"
                      style={
                        sundays.includes(Number(day.id))
                          ? { color: "red" }
                          : null
                      }
                    >
                      {day.id}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.workStart)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.workEnd)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {getTime(day.haltStart)}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {getTime(day.haltEnd)}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.totalWork === "NaN:NaN" ? null : day.totalWork}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 62, minWidth: 62 }}
          >
            <TableBody>
              <TableRow style={{ height: 99 }}>
                <TableCell border={1} component="th" scope="row" align="center">
                  <p style={{ writingMode: "vertical-lr" }}>UKUPNO</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto" }}
                >
                  x
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto" }}
                >
                  x
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto" }}
                >
                  x
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto" }}
                >
                  {addTimes(sumItems("totalWork")) === "00:00"
                    ? null
                    : addTimes(sumItems("totalWork"))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Paper className={classes.paper}>
          <div
            className={classes.div}
            style={{ maxWidth: 250, flexWrap: "wrap", fontFamily: "Roboto" }}
          >
            <table
              className={style.tableRotate}
              style={{ maxWidth: 250, width: "100%" }}
            >
              <tbody>
                <tr>
                  <td>Terenski rad</td>
                </tr>
                <tr>
                  <td style={{ fontSize: 13 }}>
                    Sati pripravnosti, te sati rada po pozivu
                  </td>
                </tr>
              </tbody>
            </table>

            <div style={{ display: "flex" }}>
              <div>
                <table
                  className={style.tableRotate}
                  style={{ width: 32.5, height: 554 }}
                >
                  <tbody>
                    <tr>
                      <td style={{ height: 250, flexShrink: 0 }}>
                        <p
                          style={{
                            writingMode: "vertical-rl",
                            height: 250,
                          }}
                        >
                          Vrijeme nenazočnosti na poslu
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style={{ display: "flex", flexWrap: "wrap", maxWidth: 250 }}>
                <table
                  className={style.tableRotate}
                  style={{ width: 33, height: 100 }}
                >
                  <tbody>
                    <tr>
                      <td style={{}}>
                        <p
                          style={{
                            writingMode: "vertical-lr",
                          }}
                        >
                          Odmor
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <table className={style.tableRotate} style={{ width: 184 }}>
                    <tbody>
                      <tr>
                        <td>Dnevni odmor</td>
                      </tr>
                      <tr>
                        <td>Tjedni odmor</td>
                      </tr>
                      <tr>
                        <td>Godišnji odmor</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table
                    className={style.tableCollection}
                    style={{ width: 217 }}
                  >
                    <tbody>
                      <tr>
                        <td>Plaćeni neradni dani i blagdani</td>
                      </tr>
                      <tr>
                        <td className={style.mozPad}>
                          Privremena nesposobnost za rad (bolovanje)
                        </td>
                      </tr>
                      <tr>
                        <td>Komplikacije u trudnoći</td>
                      </tr>
                      <tr>
                        <td>Rodiljni dopust</td>
                      </tr>
                      <tr>
                        <td>Roditeljski dopust</td>
                      </tr>
                      <tr>
                        <td>Mirovanje radnog odnosa</td>
                      </tr>
                      <tr>
                        <td className={style.mozPad}>
                          Korištenje drugih prava sukladno drugim propisima
                        </td>
                      </tr>
                      <tr>
                        <td>Sati plaćenog dopusta</td>
                      </tr>
                      <tr>
                        <td>Sati NEplaćenog dopusta</td>
                      </tr>
                      <tr>
                        <td>Izostanak na zahtjev radnika</td>
                      </tr>
                      <tr>
                        <td>Izostanak s posla bez odobrenja</td>
                      </tr>
                      <tr>
                        <td>Sati provedeni u štrajku</td>
                      </tr>
                      <tr>
                        <td>Sati isključenja s rada (lockout)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Table className={`${classes.table} ${style.tableAuto}`} size="small">
            <TableBody>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.fieldWork)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.readyHours)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 34 }}
                    >
                      <p>{getTime(day.dayRest)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.weekRest)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 34 }}
                    >
                      <p>{getTime(day.yearRest)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.holidays)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 45 }}
                    >
                      <p>{getTime(day.sickLeave)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.pregnancyComplications)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.pregnantLeave)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.parentLeave)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.frozenEmployment)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>

              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 45 }}
                    >
                      <p>{getTime(day.otherRights)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.paidLeave)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.unpaidLeave)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.absenceRequest)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.unapprovedAbsence)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.strikeHours)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      <p>{getTime(day.lockoutHours)}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 62, minWidth: 62 }}
          >
            {/* change totalWork params to actual values */}
            <TableBody>
              <TableRow style={{}}>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("fieldWork")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("readyHours")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 34 }}
                >
                  {sumAllItems("dayRest")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("weekRest")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 34 }}
                >
                  {sumAllItems("yearRest")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("holidays")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 45 }}
                >
                  {sumAllItems("sickLeave")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("pregnancyComplications")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("pregnantLeave")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("parentLeave")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("frozenEmployment")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 45 }}
                >
                  {sumAllItems("otherRights")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("paidLeave")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("unpaidLeave")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("absenceRequest")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("unapprovedAbsence")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("strikeHours")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("lockoutHours")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Paper className={classes.paper} style={{ margin: "5px auto" }}>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 33, minWidth: 33, height: 100 }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ padding: 0 }}
                >
                  <p
                    style={{
                      writingMode: "vertical-rl",
                      transform: "rotate(180deg)",
                      margin: "auto",
                      height: 90,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    RAD NOĆU
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 216, minWidth: 216 }}
          >
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Redovan rad
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  className={classes.smallRow}
                >
                  Blagdanom i neradnim danom
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Prekovremeni sati rada
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table className={`${classes.table} ${style.tableAuto}`} size="small">
            <TableBody>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.nightWork === "NaN:NaN" ? null : day.nightWork}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.nightHoliday === "NaN:NaN" ? null : day.nightHoliday}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.nightExtra === "NaN:NaN" ? null : day.nightExtra}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 62, minWidth: 62 }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("nightWork")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("nightHoliday")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("nightExtra")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Paper className={classes.paper} style={{ margin: "5px auto" }}>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 33, minWidth: 33, height: 100 }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ padding: 0 }}
                >
                  <p
                    style={{
                      writingMode: "vertical-rl",
                      transform: "rotate(180deg)",
                      margin: "auto",
                      height: 90,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    RAD DANJU
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 216, minWidth: 216 }}
          >
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Redovan rad
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  className={classes.smallRow}
                >
                  Blagdanom i neradnim danom
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Prekovremeni sati rada
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table className={`${classes.table} ${style.tableAuto}`} size="small">
            <TableBody>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.dayWork === "NaN:NaN" ? null : day.dayWork}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.dayHoliday === "NaN:NaN" ? null : day.dayHoliday}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {days.map((day) => {
                  return (
                    <TableCell
                      key={day.id}
                      component="td"
                      className={classes.tableCell}
                      scope="row"
                      style={{ height: 33 }}
                    >
                      {day.dayExtra === "NaN:NaN" ? null : day.dayExtra}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classes.table}
            size="small"
            style={{ maxWidth: 62, minWidth: 62 }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("dayWork")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("dayHoliday")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ paddingLeft: 0, margin: "auto", height: 33 }}
                >
                  {sumAllItems("dayExtra")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>

        <Table
          style={{
            width: 214,
            alignSelf: "flex-end",
            marginTop: "-5px",
            marginRight: 1,
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell
                style={{ padding: 4, border: "1px solid black", width: 150 }}
              >
                UKUPNO:
              </TableCell>

              <TableCell className={classes.extraPadding}>
                {addTimes(allItemsSum)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default React.memo(TimesheetTable);
